import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';

@Directive({
  selector: '[clickOptionButton]'
})
export class ClickOptionButtonDirective {

  @Output('clickOptionButton') clickOptionButton: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('click', ['$event'])
  async onClick($event) {
    const worklistIonItemSlidingElement: IonItemSliding = this.getWorklistIonItemSlidingElement($event);
    if (await worklistIonItemSlidingElement.getOpenAmount()) {
      this.clickOptionButton.emit($event);
    }
  }

  private getWorklistIonItemSlidingElement($event): any {
    return $event.target.parentElement.parentElement;
    /*const worklistIonItemSlidingId = `${WORKLIST_ION_ITEM_SLIDING_PREFIX}${this.data.id}`;
    const worklistIonItemSlidingElement = this.elementRef.nativeElement.children[worklistIonItemSlidingId];
    if (!worklistIonItemSlidingElement) {
      throw new Error(`DOM element with id '${worklistIonItemSlidingId}' was not found`);
    }
    return worklistIonItemSlidingElement;*/
  }
}
