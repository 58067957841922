import {Injectable} from '@angular/core';
import {LocalStorageService} from './local-storage.service';
import * as moment from 'moment';

const key = 'CACHED::::_';

@Injectable({
  providedIn: 'root'
})
export class CachedStorageService {

  constructor(private localStorage: LocalStorageService) {
  }

  retrieve(storageName: string, getExpired: boolean) {
    const value = this.localStorage.getObject(key + storageName);

    if (value === null) {
      return null;
    }

    if (moment(value.expireAt) < moment() && !getExpired) {
      return null;
    }

    return value.obj;
  }

  remove(storageName) {
    this.localStorage.setObject(key + storageName, null);
  }

  store(storageName: string, storageValue, time: number) {
    this.localStorage.setObject(key + storageName, {
      expireAt: moment().add(time, 'minutes'),
      obj: storageValue
    });
  }
}
