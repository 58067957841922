import { Pipe, PipeTransform } from '@angular/core';
import { WorklistStatuses } from '../enums/worklist-stauses.enum';

@Pipe({
  name: 'statusIcon'
})
export class StatusIconPipe implements PipeTransform {

  transform(status: string): string {
    switch (status) {
      case WorklistStatuses.New:
      case WorklistStatuses.SurveyStarted:
      case WorklistStatuses.SurveyFinished:
        return 'receipt-outline';
      case WorklistStatuses.ExamWaiting:
        return 'timer-outline'
      case WorklistStatuses.ExamIncomplete:
      case WorklistStatuses.ExamInProgress:
      case WorklistStatuses.ExamPaused:
      case WorklistStatuses.ExamStopped:
      case WorklistStatuses.Finished:
      default:
        return 'pulse-outline';
    }
  }

}
