import {Directive, ElementRef, HostListener} from '@angular/core';
import {backspaceKeyCode, leftArrowKeyCode, rightArrowKeyCode} from '../constants/keyboard-key-code.constants';
import {onlyNumbersRegex} from '../constants/regex.constants';
import {keydownEvent, pasteEvent} from '../constants/event.constants';
import {textPlainDataType} from '../constants/data-type.constants';


@Directive({
  selector: '[appNumbersOnly]'
})
export class NumbersOnlyDirective {
  @HostListener(keydownEvent, ['$event']) function(event: KeyboardEvent) {
    const inputChar = event.key;

    const ignoredKeyCodes = [
      backspaceKeyCode,
      leftArrowKeyCode,
      rightArrowKeyCode
    ];
    const shouldIgnore = event.ctrlKey || ignoredKeyCodes.some((x) => x === event.keyCode);
    if (!shouldIgnore && !onlyNumbersRegex.test(inputChar)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener(pasteEvent, ['$event']) handlePaste(event) {
    const text = (event.originalEvent || event).clipboardData.getData(textPlainDataType);
    if (!onlyNumbersRegex.test(text)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  constructor(private el: ElementRef) {

  }

}
