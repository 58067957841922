import { Injectable } from '@angular/core';
import { WorklistTagsProvider } from '../../../../app/modules/core/contracts/worklist-tags.provider';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

export interface WorklistTag {
  id: number;
  label: string;
  value: string;
}

@Injectable()
export class NextWorklistTagsProviderService implements WorklistTagsProvider {
  constructor(private httpClient: HttpClient) {
  }

  getAvailableTags(): Promise<WorklistTag[]> {
    const url = environment.serverUrl + '/api/WorklistModule.Api/WorklistTag';
    return this.httpClient.get<WorklistTag[]>(url).toPromise();
  }

  isAllowedToChangeTags(worklist): Promise<boolean> {
    return Promise.resolve(true);
  }

  saveToggleTag(worklistId, tag): Promise<any> {
    const url = environment.serverUrl + '/api/WorklistModule.Api/WorklistTag/worklist';
    return this.httpClient.post(url, {
      worklistId,
      tagId: tag.id
    }).toPromise();
  }
}
