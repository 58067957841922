import { NETWORK_UNAVAILABLE } from "../../core/constants/message.constants";

export const CONSTANT_STRINGS = {
  errorMessages: {
    multipleReductionScarOnSameBreast: 'Cannot have more than one reduction scar on the same breast',
    multipleMastectomiesOnSameBreast: 'Cannot have more than one mastectomy on the same breast',
    cannotDrawMoreReductionScars: 'You cannot draw more Reduction Scars',
    cannotDrawMoreMastectomies: 'You cannot draw more mastectomies',
    offlineModeFailed: '',
    reconnectingFailed: 'Reconnecting Failed. Before you continue to work, please fix your WiFi network issue. Offline mode is now enabled.',
  },
  aAgeConfigId: 'A_AGE',
  allOption: 'All',
  DefaultSortOption: 'DEFAULT_SORT',
  AppoinmentSortOptionAsc: 'APPOINTMENTS_ASC',
  AppoinmentSortOptionDesc: 'APPOINTMENTS_DESC',
  UltrasoundScanTechniqueMultipleScans: 'Multiple Scans',
  UltrasoundScanTechninqueNoScans: '',
  worklistStatuses: {
    New: {
      status: 'New',
      statusAlias: 'Survey Assigned',
    },
    SurveyStarted: {
      status: 'SurveyStarted',
      statusAlias: 'Survey Started',
    },
    SurveyFinished: {
      status: 'SurveyFinished',
      statusAlias: 'Survey Completed',
    },
    ExamWaiting: {
      status: 'ExamWaiting',
      statusAlias: 'Waiting for exam',
    },
    ExamIncomplete: {
      status: 'ExamIncomplete',
      statusAlias: 'Exam Incomplete',
    },
    ExamInProgress: {
      status: 'ExamInProgress',
      statusAlias: 'Exam Started',
    },
    ExamPaused: {
      status: 'ExamPaused',
      statusAlias: 'Exam Paused',
    },
    ExamStopped: {
      status: 'ExamStopped',
      statusAlias: 'Exam Ended',
    },
    Finished: {
      status: 'Finished',
      statusAlias: 'Exam Finished',
    },
    TakeExam: {
      status: 'TakeExam',
      statusAlias: 'Take Exam',
    },
    ReviewComplete: {
      status: 'ReviewComplete',
      statusAlias: 'Review Complete',
    },
  },
  worklistAddditionalInfo: {
    ReleasePatient: {
      infoCode: 'ReleasePatient',
      infoAlias: 'Release Patient',
    },
    AdditionalImaging: {
      infoCode: 'AdditionalImaging',
      infoAlias: 'Additional Imaging',
    },
    Other: {
      infoCode: 'Other',
      infoAlias: 'Other',
    },
  },
  Axilla: 'Axilla',
  DateMinValue: -8640000000000000,
  DateMaxValue: 8640000000000000,
  DateTimeNullValue: '0001-01-01T00:00:00+00:00',
  IMPLANTS_TYPE: 'IMPLANTS',
  PERSONAL_BRCA1: 'PERSONAL_BRCA1',
  PERSONAL_BRCA2: 'PERSONAL_BRCA2',
  networkUnavailableMessage: NETWORK_UNAVAILABLE,
  noTagsOption: 'noTags',
  arrivalStatuses: {
    PatientArrived: 'PatientArrived',
    ReadyForExam: 'ReadyForExam'
  },
  sides: [{
    key: 'L',
    value: 'Left'
  }, {
    key: 'R',
    value: 'Right'
  }, {
    key: 'B',
    value: 'Both'
  }, {
    key: 'U',
    value: 'No side'
  }]
};

export const DIAGRAM_PAPER_SETTINGS = {
  small: {
    width: 800,
  },
  large: {
    width: 1024,
  },
};

export const STATE_DATA = [
  { name: 'ARMED FORCES AMERICAS', code: 'AA' },
  { name: 'ARMED FORCES EUROPE', code: 'AE' },
  { name: 'ARMED FORCES PACIFIC', code: 'AP' },
  { name: 'ALABAMA', code: 'AL' },
  { name: 'ALASKA', code: 'AK' },
  { name: 'AMERICAN SAMOA', code: 'AS' },
  { name: 'ARIZONA', code: 'AZ' },
  { name: 'ARKANSAS', code: 'AR' },
  { name: 'CALIFORNIA', code: 'CA' },
  { name: 'COLORADO', code: 'CO' },
  { name: 'CONNECTICUT', code: 'CT' },
  { name: 'DELAWARE', code: 'DE' },
  { name: 'DISTRICT OF COLUMBIA', code: 'DC' },
  { name: 'FLORIDA', code: 'FL' },
  { name: 'FEDERATED STATES OF MICRONESIA', code: 'FM' },
  { name: 'GEORGIA', code: 'GA' },
  { name: 'GUAM', code: 'GU' },
  { name: 'HAWAII', code: 'HI' },
  { name: 'IDAHO', code: 'ID' },
  { name: 'ILLINOIS', code: 'IL' },
  { name: 'INDIANA', code: 'IN' },
  { name: 'IOWA', code: 'IA' },
  { name: 'KENTUCKY', code: 'KE' },
  { name: 'KANSA', code: 'KS' },
  { name: 'KENTUCKY', code: 'KY' },
  { name: 'LOUSIANA', code: 'LA' },
  { name: 'MAINE', code: 'ME' },
  { name: 'MARYLAND', code: 'MD' },
  { name: 'MASSACHUSETTS', code: 'MA' },
  { name: 'MARSHALL ISLANDS', code: 'MH' },
  { name: 'MICHIGAN', code: 'MI' },
  { name: 'MINNESOTA', code: 'MN' },
  { name: 'MISSISSIPPI', code: 'MS' },
  { name: 'MISSOURI', code: 'MO' },
  { name: 'MARIANA ISLANDS', code: 'MP' },
  { name: 'MONTANA', code: 'MT' },
  { name: 'NEBRASKA', code: 'NE' },
  { name: 'NEVADA', code: 'NV' },
  { name: 'NEW HAMPSHIRE', code: 'NH' },
  { name: 'NEW JERSEY', code: 'NJ' },
  { name: 'NEW MEXICO', code: 'NM' },
  { name: 'NEW YORK', code: 'NY' },
  { name: 'NORTH CAROLINA', code: 'NC' },
  { name: 'NORTH DAKOTA', code: 'ND' },
  { name: 'OHIO', code: 'OH' },
  { name: 'OKLAHOMA', code: 'OK' },
  { name: 'OREGON', code: 'OR' },
  { name: 'PENNSYLVANIA', code: 'PA' },
  { name: 'PUERTO RICO', code: 'PR' },
  { name: 'PALAU', code: 'PW' },
  { name: 'RHODE ISLAND', code: 'RI' },
  { name: 'SOUTH CAROLINA', code: 'SC' },
  { name: 'SOUTH DAKOTA', code: 'SD' },
  { name: 'TENNESSEE', code: 'TN' },
  { name: 'TEXAS', code: 'TX' },
  { name: 'UTAH', code: 'UT' },
  { name: 'VERMONT', code: 'VT' },
  { name: 'VIRGIN ISLANDS', code: 'VI' },
  { name: 'VIRGINIA', code: 'VA' },
  { name: 'WASHINGTON', code: 'WA' },
  { name: 'WEST VIRGINIA', code: 'WV' },
  { name: 'WISCONSIN', code: 'WI' },
  { name: 'WYOMING', code: 'WY' },
];


export const NEW_ULTRASOUND_TECHNIQUE_DESCRIPTION = 'New Technique';
export const ULTRASOUND_TECHNIQUE_OPTION_L_FROM_NAME = 'Lfrom';
export const ULTRASOUND_TECHNIQUE_OPTION_L_TO_NAME = 'Lto';
export const ULTRASOUND_TECHNIQUE_OPTION_LIST_TYPE = 'LIST';
export const ULTRASOUND_RECORD_AXILLA_PROPERTY_NAME = 'Axilla';
export const ULTRASOUND_TECHNIQUE_OPTION_YES_NO_TYPE = 'YESNO';
export const ULTRASOUND_TECHNIQUE_OPTION_TEXT_TYPE = 'TEXT';
export const ULTRASOUND_TECHNIQUE_OPTION_DATE_TYPE = 'DATE';
export const ULTRASOUND_TECHNIQUE_OPTION_NOTE_TYPE = 'Note';
export const SELECTED_L_FROM_LOCATIONS_PROPERTY_KEY = 'selectedLfromLocations';
export const SELECTED_L_TO_LOCATIONS_PROPERTY_KEY = 'selectedLtoLocations';
export const IS_SELECTED_PROPERTY_KEY = 'isSelected';
export const STUDY_PROPERTY_KEY = 'study';
export const DESC_PROPERTY_KEY = 'desc';
export const SIDES = [
  {
    key: 'L',
    value: 'Left',
  }, {
    key: 'R',
    value: 'Right',
  }, {
    key: 'B',
    value: 'Both',
  }, {
    key: 'U',
    value: 'No side',
  }
];
