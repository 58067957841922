export enum WorklistStatuses {
  New = 'New',
  SurveyStarted = 'SurveyStarted',
  SurveyFinished = 'SurveyFinished',
  ExamWaiting = 'ExamWaiting',
  ExamInProgress = 'ExamInProgress',
  ExamPaused = 'ExamPaused',
  ExamStopped = 'ExamStopped',
  ExamIncomplete = 'ExamIncomplete',
  Finished = 'Finished',
  TakeExam = 'TakeExam',
  ReviewComplete = 'ReviewComplete'
}
