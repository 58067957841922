export const ChatModuleConfiguration = {
  apiEndpoint: '',
  timeFormat: 'hh:mm:ss A',
  dateFormat: 'MM-DD-YYYY',
  chatHubName: 'LuminaryWorklistServiceChatHub',
  chatPageUrl: '',
  notificationSoundUrl: '',
  storageKey: {
    chatLoginJwt: 'CHAT_LOGIN_JWT',
    userLoggedInInfo: 'USER_LOGGED_IN_INFO',
    userInfo: 'USER_INFO',
    userLoginCredential: 'USER_LOGIN_CREDS'
  },
  authenticationTokenName: 'AuthToken',
  encryptionKey: 'fuqIDKrWEce3Z3QtnZC4sn6NlP1lK9OiUNdrIWpCXsdmQwmRmGoQLEZKNpyo6QzJ'
};
