import { Pipe, PipeTransform } from '@angular/core';
import { WorklistStatuses } from '../enums/worklist-stauses.enum';

@Pipe({
  name: 'statusColor'
})
export class StatusColorPipe implements PipeTransform {

  transform(status: string): string {
    switch (status) {
      case WorklistStatuses.New:
      case WorklistStatuses.SurveyStarted:
      case WorklistStatuses.SurveyFinished:
        return 'energized';
      case WorklistStatuses.ExamWaiting:
        return 'assertive';
      case WorklistStatuses.ExamIncomplete:
      case WorklistStatuses.ExamInProgress:
      case WorklistStatuses.ExamPaused:
      case WorklistStatuses.ExamStopped:
      case WorklistStatuses.Finished:
        return 'royal';
      default:
        return 'dark';
    }
  }

}
