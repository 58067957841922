import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
}
)
export class AccountService {
  constructor(protected $http: HttpClient) {
  }

  login(portalUrl, username, password) {
    const url = `${portalUrl}/api/auth/login`;
    const body = {
      UserName: username,
      Password: password
    };
    return this.$http.post(url, body).toPromise();
  }

  setToken(token) {
    // TODO: 311 Implement this method
  }

  registerDevice(portalUrl, deviceId) {
    const url = `${portalUrl}/api/auth/registerDevice`;
    const body = { deviceId };
    return this.$http.post(url, body).toPromise();
  }

  unregisterDevice(portalUrl, deviceId) {
    const url = `${portalUrl}/api/auth/unregisterDevice`;
    const body = { deviceId };
    return this.$http.post(url, body).toPromise();
  }

  getPortalSecurityToken(portalUrl) {
    const url = `${portalUrl}/api/techpadSecurity/generateToken`;
    return this.$http.get(url).toPromise();
  }

  changeTechnologistPassword(username, newPassword, portalUrl, authToken) {
    const url = `${portalUrl}/api-client/technologist`;
    const body = {
      email: username,
      newPassword: newPassword
    };
    const options = {
      headers: { AuthToken: authToken }
    };
    return this.$http.post(url, body, options).toPromise();
  }

  getPasswordRequirements(portalUrl) {
    const url = `${portalUrl}/api-client/technologist/getPasswordRequirements`;
    return this.$http.get(url).toPromise();
  }
}
