import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SignalrService } from '../../services/signalr.service';
import { SubscriptionService } from '../../services/subscription.service';
import { RealtimeConnectionService } from '../../services/realtime.service';

@Component({
  selector: 'app-network-status-checker',
  templateUrl: './network-status-checker.component.html',
  styleUrls: ['./network-status-checker.component.scss'],
})
export class NetworkStatusCheckerComponent implements OnInit, OnDestroy {
  isOnlineSubscription: Subscription;
  isOnline: boolean;

  constructor(private realtimeConnectionService: RealtimeConnectionService,
              private subscriptionService: SubscriptionService) {
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribe(this.isOnlineSubscription);
  }

  ngOnInit() { }

}
