import {Directive, HostListener} from '@angular/core';
import {NgModel} from '@angular/forms';
import {backspaceKeyCode, leftArrowKeyCode, rightArrowKeyCode} from '../constants/keyboard-key-code.constants';
import {
  fullPhoneNumberRegex,
  onlyNumberGlobalRegex,
  onlyNumbersRegex,
  phoneCodeRegex,
  phoneNumberBeforeDashRegex
} from '../constants/regex.constants';
import {keydownEvent, pasteEvent} from '../constants/event.constants';
import {textPlainDataType} from '../constants/data-type.constants';
import {
  fullPhoneNumberPattern,
  phoneCodePattern,
  phoneNumberBeforeDashPattern
} from '../constants/pattern.constants';

const phoneCodeLength = 3;
const phoneNumberBeforeDashLength = 6;
const fullPhoneNumberLength = 10;

@Directive({
  selector: '[ngModel][appPhoneNumber]',
  providers: [NgModel],
  host: {
    '(ngModelChange)': 'onModelChange($event)'
  }
})
export class PhoneNumberDirective {
  lastValue: any;

  constructor(public model: NgModel) {
  }

  @HostListener(keydownEvent, ['$event']) function(event: KeyboardEvent) {
    const pattern = onlyNumbersRegex;
    const inputChar = event.key;

    const ignoredKeyCodes = [
      backspaceKeyCode,
      leftArrowKeyCode,
      rightArrowKeyCode
    ];
    const shouldIgnore = event.ctrlKey || ignoredKeyCodes.some((x) => x === event.keyCode);
    if (!shouldIgnore && !pattern.test(inputChar)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onModelChange($event) {
    const formattedPhoneNumber = this.getFormattedPhoneNumber($event);
    this.lastValue = formattedPhoneNumber;
    this.model.valueAccessor.writeValue(formattedPhoneNumber);
    this.model.viewToModelUpdate(formattedPhoneNumber);
  }

  @HostListener(pasteEvent, ['$event']) handlePaste(event) {
    const text = (event.originalEvent || event).clipboardData.getData(textPlainDataType);

    if (!onlyNumbersRegex.test(text)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  getFormattedPhoneNumber = (value): string => {
    if (this.lastValue === value) {
      return;
    }
    let newValue = value.replace(onlyNumberGlobalRegex, '');
    if (newValue.length === 0) {
      newValue = '';
    } else if (newValue.length <= phoneCodeLength) {
      newValue = newValue.replace(phoneCodeRegex, phoneCodePattern);
    } else if (newValue.length <= phoneNumberBeforeDashLength) {
      newValue = newValue.replace(phoneNumberBeforeDashRegex, phoneNumberBeforeDashPattern);
    } else {
      newValue = newValue.substring(0, fullPhoneNumberLength);
      newValue = newValue.replace(fullPhoneNumberRegex, fullPhoneNumberPattern);
    }

    return newValue;
  };

}
