import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { applicationConfiguration } from '../../../config';
import { AuthProvider } from '../../core/contracts/auth.provider';
import { EventsMgrService } from '../../core/services/events-mgr.service';
import { HeaderService } from '../../core/services/header.service';
import { LoadingService } from '../../core/services/loading.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { ModalService } from '../../core/services/modal.service';
import { PubsubService } from '../../core/services/pubsub.service';
import { authorizationConstants } from '../constants/authorization.constants';
import { AccountService } from './account.service';

@Injectable()
export class AuthService implements AuthProvider {
  private subscription: Subscription = new Subscription();

  userLoginKey = 'UserLogin';
  magViewUrlKey = 'MagViewUrl';
  magViewAuthTokenKey = 'MagViewAuthToken';
  magviewDeviceId = 'MagViewDeviceId';
  magviewDeviceInfo = 'MagViewDeviceInfo';
  magViewUserName = 'MagViewUserName';
  magViewRoles = 'magViewRoles';
  magViewPermissions = 'magViewPermissions';

  loggedIn = null;
  _currentMagViewUrl = null;
  _ensureMagviewPortal = null;

  device: any;

  constructor(private accountService: AccountService,
    private router: Router,
    private localStorage: LocalStorageService,
    private loadingService: LoadingService,
    private headerService: HeaderService,
    private eventsMgr: EventsMgrService,
    private injector: Injector,
    private pubsubService: PubsubService) {

    this.loggedIn = this.localStorage.get(this.userLoginKey);

    this._ensureMagviewPortal = new Promise<void>(resolve => {
      if (applicationConfiguration.magviewUrl) {
        let url = applicationConfiguration.magviewUrl;
        if (!_.startsWith(url, 'http://') && !_.startsWith(url, 'https://') && !_.startsWith(url, '//')) {
          url = '//' + url;
        }
        this._currentMagViewUrl = url;
        resolve();
      } else {
        resolve();
      }
    });

    this.subscription.add(this.headerService.logoutEvent.subscribe({
      next: async () => {
        // TODO: 311 - figure out if we still need all events
        this.eventsMgr.send('diagram:clearProblemSelection');
        this.eventsMgr.send('diagram:lockDragging');
        const params = {
          confirmationId: 'logout-modal'
        };
        const modalService = this.injector.get(ModalService);
        const confirmed = await modalService.showConfirmation(null, 'Are you sure you want to log out of Techpad ?', params);
        if (!confirmed) {
          this.eventsMgr.send('diagram:unlockDragging');
        } else {
          // if (this.techpadConfig.settings && this.techpadConfig.settings.isShowChatMenuItem) {
          // TODO: 311 - since we will have chat component inside current app we don't need pubsubService anymore. Also, need to handle spinner
          // this.loadingService.showLoadingScreen({
          // template: 'Logging out from chat...'
          // });
          // this.pubsubService.dispatch('USER_LOGGED_OUT');
          // } else {
          this.logout();
          // this.eventsMgr.send('diagram:unlockDragging');
          // }
        }
        // 311 ----------
      }
    }));
  }

  getTechpadDiagramHeaders() {
    const requestHeaderKeyName = authorizationConstants.techpadDiagram.requestHeaderKeyName;
    const requestHeaderKeyValue = authorizationConstants.techpadDiagram.requestHeaderKeyValue;
    const headers = {};
    headers[requestHeaderKeyName] = requestHeaderKeyValue;
    return headers;
  }

  async changeTechnologistPassword(username, newPassword, portal, authToken) {
    const portalUrl = _.trim(portal.url, '/');
    await this.accountService.changeTechnologistPassword(username, newPassword, portalUrl, authToken);
  }

  // result contains any error description text returned from the plugin call
  errorHandler(error) {
    alert('Cannot retrieve push notification token: ' + error);
  }

  // result contains any message sent from the plugin call
  successHandler(result) {
    let token;
    if (this.device.platform === 'android' || this.device.platform === 'Android') {
      token = 'android:' + result;
    } else {
      token = result;
    }

    this.localStorage.set(this.magviewDeviceId, token);
  }

  currentMagViewUrl() {
    if (this._currentMagViewUrl) {
      return this._currentMagViewUrl;
    }

    this._currentMagViewUrl = this.localStorage.get(this.magViewUrlKey);
    return this._currentMagViewUrl;
  }

  async login(username, password, portal, onTechnologistPasswordChange) {
    const portalUrl = _.trim(portal.url, '/');

    return this.accountService.login(portalUrl, username, password)
      .then(async (resp: any) => {
        if (!resp.success) {
          return Promise.reject(resp);
        }

        if (resp.isForcePasswordChange && onTechnologistPasswordChange && typeof onTechnologistPasswordChange === 'function') {
          await onTechnologistPasswordChange({ username, password, portal, authToken: resp.authToken });
        }

        this.loggedIn = true;

        this.localStorage.set(this.magViewAuthTokenKey, resp.authToken);
        this.localStorage.set(this.userLoginKey, resp.login || username);
        this.localStorage.set(this.magViewUrlKey, portalUrl);
        this.localStorage.set(this.magViewUserName, resp.name);
        this.localStorage.set(this.magViewRoles, resp.roles);
        this.localStorage.setObject(this.magViewPermissions, resp.permissions);
        this.accountService.setToken(this.getAuthToken());
        this.registerDevice();
        // TODO: signalrService.connect();
      });
  }

  async logout() {
    await this.loadingService.showLoadingScreen({ message: 'Logging out...' });
    try {
      this.unregisterDevice();

      this.loggedIn = false;
      this._currentMagViewUrl = null;
      [
        this.userLoginKey,
        this.magViewUrlKey,
        this.magViewAuthTokenKey,
        this.magViewUserName,
        this.magViewRoles,
      ].forEach((key) => {
        this.localStorage.delete(key);
      });

      // TODO: signalrService.disconnect();
      this.localStorage.cleanUp();

      this.router.navigate(['login']);
    } catch (error) {
      console.error();
    } finally {
      await this.loadingService.hideLoadingScreen();
    }
  }

  checkPortalUrl() {
    if (!this.loggedIn) {
      return;
    }

    // TODO: signalrService.connect();
  }

  getAuthToken() {
    return this.localStorage.get(this.magViewAuthTokenKey);
  }

  getUserName() {
    return this.localStorage.get(this.userLoginKey);
  }

  getDisplayName() {
    return this.localStorage.get(this.magViewUserName);
  }

  getRoles() {
    return this.localStorage.get(this.magViewRoles);
  }

  getPermissions() {
    return this.localStorage.get(this.magViewPermissions);
  }

  isRadiologist() {
    const roles = this.localStorage.get(this.magViewRoles);
    const rolesArray = _.split(roles, ',');
    return _.some(rolesArray, role => role === 'Radiologist');
  }

  registerDevice() {
    if (!!this.localStorage.get(this.magviewDeviceId)) {
      this.accountService.registerDevice(this.currentMagViewUrl(), this.localStorage.get(this.magviewDeviceId));
    }
  }

  registerRunningApp() {
    try {
      const device = (window as any).device || { platform: 'Browser', userAgent: navigator.userAgent };
      // let deviceInfo = {
      //   model: device.model,
      //   platform: device.platform,
      //   version: device.version,
      //   uuid: device.uuid,
      //   serial: device.serial
      // };
      this.localStorage.setObject(this.magviewDeviceInfo, device);

      let model = {};
      const isAndroid = device.platform === 'android' || device.platform === 'Android';

      if (isAndroid) {
        return; // TODO: Fix if push notification support needed in Android
      }
      if (isAndroid) {
        model = {
          senderID: '223953128875',
          ecb: 'onNotificationGCM'
        };
      } else {
        model = {
          badge: 'true',
          sound: 'true',
          alert: 'true',
          ecb: 'onNotificationAPN'
        };
      }
      const pushNotification = (window as any).plugins.pushNotification;
      pushNotification.register(
        isAndroid ? () => {
        } : this.successHandler,
        this.errorHandler,
        model
      );
    } catch (ex) {
      console.log(ex);
    }
  }

  unregisterDevice() {
    const currentMagviewUrl = this.currentMagViewUrl();
    const deviceId = this.localStorage.get(this.magviewDeviceId);
    if (!currentMagviewUrl) {
      return;
    }
    this.accountService.unregisterDevice(currentMagviewUrl, deviceId);
    this.localStorage.set(this.magviewDeviceId, '');
  }

  getPortalSecurityToken() {
    return this.accountService.getPortalSecurityToken(this.currentMagViewUrl());
  }

  async getPasswordRequirements(portal) {
    const portalUrl = _.trim(portal.url, '/');
    return this.accountService.getPasswordRequirements(portalUrl);
  }

  ensureMagviewPortal() {
    return this._ensureMagviewPortal;
  }
}
