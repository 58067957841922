export const LOOKUPS = {
  statuses: ['New', 'SurveyStarted', 'SurveyFinished', 'ExamWaiting', 'ExamInProgress',
    'ExamPaused', 'ExamStopped', 'ExamIncomplete', 'Finished'],
  examTypes: ['Mammography', 'Ultrasound', 'Tomosynthesis', 'Ductography', 'Breast MRI', 'Bone Densitometry', 'Lung', 'Other'],
  examShortCodeTypes: ['Mammo', 'US', 'Tomo', 'Duct.', 'MRI', 'Dexa', 'Lung', 'Other'],
  visitTypes: [
    {
      code: 'D',
      label: 'Diagnostic'
    },
    {
      code: 'A',
      label: 'Additional evaluation'
    },
    {
      code: 'S',
      label: 'Screening'
    }
  ],
  allStatusGroups: [
    {
      name: 'Patient Survey',
      statuses: ['New', 'SurveyStarted', 'SurveyFinished'],
      key: 'New',
      onlyForRoles: ['Technologist', 'Radiologist']
    },
    {
      name: 'Waiting for Exam',
      statuses: ['ExamWaiting'],
      key: 'ExamWaiting',
      onlyForRoles: ['Technologist', 'Radiologist']
    },
    {
      name: 'Performing Exam',
      statuses: ['ExamInProgress', 'ExamPaused', 'ExamIncomplete', 'Finished'],
      key: 'ExamInProgress',
      onlyForRoles: ['Technologist', 'Radiologist']
    },
    {
      name: 'Exam Ended',
      statuses: ['ExamStopped'],
      key: 'ExamStopped',
      onlyForRoles: ['Technologist', 'Radiologist']
    }]
};
