import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private isOnline$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  // TODO: 311 - signalr - implement isOnline in signalr
  isOnline = this.isOnline$.asObservable();
  // 311 ----------

  magViewUrlKey = 'MagViewUrl';
  magViewAuthTokenKey = 'MagViewAuthToken';
  magViewHospitalKey = 'hospital';
  pingTimeout = 3000;
  pingUrl = '/ping/ping.gif';
  reconnectAttempt = 0;

  constructor() { }

  updateIsOnline(isOnline: boolean) {
    this.isOnline$.next(isOnline);
  }

  tryReconnectAgain() {
    throw new Error('Method not implemented.');
  }

}
