import { Injectable } from '@angular/core';
import { AjaxService } from '../../core/services/ajax.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class WorklistFilterSettingsService {

  constructor(private authService: AuthService,
    private ajaxService: AjaxService) { }

  getMySettings() {
    return this.ajaxService.get/*AndCache*/(this.authService.currentMagViewUrl() + '/api/worklistFilterSettings/mySettings');
  }

  saveMySettings(settings) {
    return this.ajaxService.post(this.authService.currentMagViewUrl() + '/api/worklistFilterSettings/mySettings', settings);
  }
}
