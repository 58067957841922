import { Injectable } from '@angular/core';
import { ModalService } from '../../core/services/modal.service';
import { WorklistService } from './worklist.service';

@Injectable({
    providedIn: 'root'
})
export class WorklistLockService {

    constructor(private modalService: ModalService,
        private Worklist: WorklistService) {
    }

    async checkIfLocked(worklistId, hideAlert = false) {
        let isLocked: boolean = false;
        const studyLockInfo: any = await this.Worklist.checkStudyLock(worklistId);
        if (studyLockInfo.isLock) {
            isLocked = true;
            if (!hideAlert) {
                const name = studyLockInfo.currentUser.userName;
                const message = `This exam is now taken by ${name}. You cannot perform this action`;
                this.modalService.showAlert(message);
            }
        }
        return isLocked;
    }
}