import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelize'
})
export class CamelizePipe implements PipeTransform {

  transform(input: string): string {
    return input.toLowerCase()
      .replace(/(?:^|\s)\w/g, (match) => {
        return match.toUpperCase();
      });
  }

}
