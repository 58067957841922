import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ion-select-with-icon',
  templateUrl: './ion-select-with-icon.component.html',
  styleUrls: ['./ion-select-with-icon.component.scss'],
})
export class IonSelectWithIconComponent implements OnInit {
  @Input() shouldIncludeSelectOption: boolean;

  @Input() selectOptionDescription: string;

  @Input() selectOptionValue = '';

  @Input() disableSelectOptionAfterSelecting = false;

  @Input() shouldHaveRemoveButton = true;

  @Input() placeholder: string;

  @Input() options: any[];

  @Input() optionsValuePropertyName: string;

  @Input() optionsDescriptionPropertyName: string;

  @Input() multiple: boolean;

  @Input() disabled: boolean;

  @Input() id: string;

  @Input() interface: string;

  @Input() ionSelectClasses: string;

  @Input() ionIconName = 'close-circle-outline';

  @Input() attachToFormGroup: boolean;

  @Input() formControlName: string;

  @Input() formGroup: FormGroup;

  @Input() model: any;

  @Input() interfaceOptions: any;

  @Input() compareWith: any;

  @Input() overrideButtonClick: boolean;

  @Output() modelChange = new EventEmitter<any>();

  @Output() buttonClick = new EventEmitter<any>();

  @Output() selectAction = new EventEmitter<any>();

  emptyValue: any = null;

  constructor() {
  }

  ngOnInit() {
    this.emptyValue = this.shouldIncludeSelectOption ? this.selectOptionValue : null;
  }

  onSelectChange($event) {
    this.modelChange.emit(this.model);
    if (this.selectAction) {
      this.selectAction.emit(this.model);
    }
  }

  onButtonClick($event) {
    if (this.overrideButtonClick) {
      if (this.buttonClick) {
        this.buttonClick.emit($event);
      }
    } else {
      this.model = this.emptyValue;
      this.modelChange.emit(this.model);
      if (this.buttonClick) {
        this.buttonClick.emit($event);
      }
    }
  }
}
