import { Injectable } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { AlertButton } from '@ionic/core/dist/types/components/alert/alert-interface';
import * as _ from 'lodash';
import { applicationConfiguration } from 'src/app/config';
// TODO: refactor
import {
  LinkedStudiesModalComponent,
  LinkedStudiesModalComponentResult,
  WorklistChangeListMessageComponent,
  WorklistChangeResourceComponent,
  WorklistChangeStatusComponent,
  WorklistChangeTagsComponent
} from '../../../pages/worklist/components';
import { ErrorMessageModalComponent } from '../components/error-message-modal/error-message-modal.component';
import { OverlayEventDetail } from '@ionic/core/dist/types/utils/overlays-interface';
import { Resource } from '../models/resource.model';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  popupsDictionary = {};

  constructor(private alertCtrl: AlertController,
              private popoverController: PopoverController,
              private modalController: ModalController) {
  }

  showConfirmation(action, text, params = {} as any) {
    return new Promise<boolean>(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: 'Techpad',
        message: text,
        id: params.confirmationId,
        buttons: [
          {
            text: params.okText || 'Yes',
            cssClass: 'yes-button',
            handler: async () => {
              if (action && typeof action === 'function') {
                await action();
              }
              resolve(true);
            }
          },
          {
            text: params.cancelText || 'No',
            cssClass: 'no-button',
            role: 'cancel',
            handler: () => {
              resolve(false);
            }
          }
        ]
      });
      await alert.present();
    });
  }

  showConfirmationAsync(text, params?) {
    params = params || {};

    return new Promise<boolean>(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: 'Techpad',
        message: text,
        buttons: [
          {
            text: params.okText || 'Yes',
            handler: () => {
              resolve(true);
            }
          },
          {
            text: params.cancelText || 'No',
            role: 'cancel',
            handler: () => {
              resolve(false);
            }
          }
        ]
      });
      await alert.present();
    });
  }

  parseError(error) {
    return (error && error.data && (error.data.Message || error.data.message)) || (error && (error.Message || error.message)) || (error && typeof error === 'string');
  }

  async showErrorAlert(error) {
    const alert = await this.alertCtrl.create({
      header: 'Techpad',
      message: error,
      buttons: ['OK']
    });
    await alert.present();
  }

  async showAlert(text, action = null, messageIdentifier?) {
    if (messageIdentifier && this.popupsDictionary[messageIdentifier]) {
      await this.popupsDictionary[messageIdentifier].dismiss();
    }

    const alert = await this.alertCtrl.create({
      header: 'Techpad',
      message: text,
      cssClass: messageIdentifier,
      buttons: [{
        text: 'OK',
        handler: () => {
          if (action) {
            action();
          }
        }
      }]
    });
    await alert.present();

    if (messageIdentifier) {
      this.popupsDictionary[messageIdentifier] = alert;
    }
  }

  async showAlertAsync(text) {
    return new Promise(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: 'Techpad',
        message: text,
        buttons: [{
          text: 'OK',
          handler: () => {
            resolve();
          }
        }]
      });
      await alert.present();
    });
  }

  async showErrorMessage(error, status = null) {
    return new Promise(async (resolve, reject) => {
      const model = Object.create({});
      model.error = error;

      console.error(error);

      // 312
      // model.$on('modal.removed', () => {
      //   resolve();
      // });

      const ignoreMessages = [
        'The provider could not determine the Decimal value. For example, the row was just created, the default for the Decimal column was not available, and the consumer had not yet set a new Decimal value.'
      ];

      if (typeof error === 'string') {
        model.error = {
          errorId: status,
          message: error
        };

      } else if (error && error.error && (error.error.exceptionMessage || error.error.message)) {

        let message = error.error.exceptionMessage || error.error.message || error;

        if (ignoreMessages.indexOf(message) > -1) {
          message = 'An error has occurred. Please contact MagView support at 800-553-8996 and report this error number: #' + status;
        }

        model.error = {
          errorId: status,
          message,
          details: error.stackTrace
        };
      } else if (error && (error.exceptionMessage || error.message)) {

        let message = error.exceptionMessage || error.message || error;

        if (ignoreMessages.indexOf(message) > -1) {
          message = 'An error has occurred. Please contact MagView support at 800-553-8996 and report this error number: #' + status;
        }

        model.error = {
          errorId: status,
          message,
          details: error.stackTrace
        };
      }
      if (!model.error) {
        model.error = 'Error Occured';
      }
      if (status === 404 || !error || error.status === 0) {
        model.error = { errorId: 404, message: 'Server is not reachable at the moment', details: error };
      }
      model.type = 'Server Error';
      model.version = applicationConfiguration.appVersionNumber;
      model.stamp = new Date();
      const modal = await this.modalController.create({
        component: ErrorMessageModalComponent,
        componentProps: _.cloneDeep(model),
        cssClass: 'error-message-modal'
      });

      modal.onWillDismiss().then(() => {
        resolve();
      });
      await modal.present();
    });
  }

  async showAdvancedConfirmation_Deprecated(params) {
    const alert = await this.alertCtrl.create({
      header: 'Techpad',
      message: params.text,
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            if (typeof params.cancelAction === 'function') {
              params.cancelAction();
            }
          }
        },
        {
          text: params.saveButtonText || 'Save & Exit',
          cssClass: 'button-positive ripple',
          handler: () => {
            if (typeof params.cancelAction === 'function') {
              params.saveAction();
            }
          }
        },
        {
          text: params.exitButtonText || 'Discard Changes',
          cssClass: 'button-positive ripple',
          handler: () => {
            params.exitAction();
          }
        }
      ]
    });
    await alert.present();

    return alert;
  }

  showAdvancedConfirmation(title, message, buttons?: AlertButton[], cssClass?) {
    return new Promise<boolean>(async resolve => {
      if (!buttons || !buttons.length) {
        buttons = [{
          text: 'No',
          handler: () => {
            resolve(false);
          }
        }, {
          text: 'Yes',
          cssClass: 'button-positive',
          handler: () => {
            resolve(true);
          }
        }];
      }
      const alert = await this.alertCtrl.create({
        message,
        header: title,
        buttons,
        cssClass
      });

      await alert.present();
    });
  }

  showWorklistFilters(currentFilters) {
    // TODO: 311 - brought from old techpad, need to fix later
    // const self = this;
    // let defer = this._qService.defer();
    // let scope = this.$rootScope.$new();

    // scope.$on('modal.removed', () => {
    //   defer.resolve();
    // });

    // // scope.model = model;
    // this.$ionicModal.fromTemplateUrl('views/templates/worklistFilters.html', ($ionicModal) => {
    //   scope.model = new self.WorklistFilterViewModel($ionicModal, currentFilters, self);
    //   $ionicModal.show();
    // }, {
    //   scope: scope,
    //   animation: 'slide-in-up'
    // });

    // return defer.promise;
    // 311 ----------
  }

  async showWorklistChangeStatus(item, event) {
    const popover = await this.popoverController.create({
      component: WorklistChangeStatusComponent,
      event,
      componentProps: { id: item.id, item }
    });

    await popover.present();
    const { data } = await popover.onDidDismiss();
    return data;
  }

  async showWorklistChangeTags(item, event) {
    const popover = await this.popoverController.create({
      component: WorklistChangeTagsComponent,
      event,
      componentProps: { id: item.id, item }
    });

    await popover.present();
    const { data } = await popover.onDidDismiss();
    return data;
  }

  // TODO: 311 check if this method working correct
  async showWorklistChangeListMessage(data, $event) {

    const initialModel = {
      listMessage: (!data.isRadiologist ? data.listMessage : data.radListMessage) || ''
    };

    const popover = await this.popoverController.create({
      component: WorklistChangeListMessageComponent,
      event: $event,
      componentProps: {
        data: data,
        initialModel
      }
    });

    await popover.present();

    await popover.onDidDismiss();

    data.isListMessageChanged = initialModel.listMessage !== data.newListMessage;

    return data;
  }

  async showWorklistChangeResource(data, $event, availableResources: Resource[]) {

    const initialModel = {
      resource: data.resource || ''
    };

    const popover = await this.popoverController.create({
      component: WorklistChangeResourceComponent,
      event: $event,
      componentProps: {
        data: data,
        initialModel,
        availableResources
      }
    });

    await popover.present();

    await popover.onDidDismiss();

    data.isResourceChanged = initialModel.resource !== data.selectedResource;

    return data;
  }

  // showWorklistChangeAdditionalInfo(model, item, event, showRemoveAdditionalInfo) {
  //   let scope = this.$rootScope.$new();
  //   scope.vm = model;
  //   scope.worklistItem = item;
  //   scope.id = item.id;
  //   scope.showRemoveAdditionalInfo = showRemoveAdditionalInfo;
  //   this.$ionicPopover.fromTemplateUrl('views/templates/dashboardChangeAdditionalInfo.html', {scope: scope})
  //     .then((popover) => {
  //       scope.modal = popover;
  //       popover.show(event);
  //     });
  // }
  //
  // showDialogWindow(templateUrl, model, closeByButtonsClickOnly, options) {
  //   if (!model.init) {
  //     throw new Error('Viewmodel for dialog window must implement init() method');
  //   }
  //
  //   const modalService = this;
  //   const ionicDatePicker = this.ionicDatePicker;
  //   let defer = this._qService.defer();
  //   let scope = this.$rootScope.$new();
  //   scope.model = model;
  //
  //   this.$ionicModal.fromTemplateUrl(templateUrl, ($ionicModal) => {
  //     scope.modal = $ionicModal;
  //     if (closeByButtonsClickOnly) {
  //       $ionicModal.backdropClickToClose = false;
  //       modalService.$rootScope.$on('$locationChangeStart', () => {
  //         modalService.$ionicModal.hide();
  //       });
  //     }
  //     model.init($ionicModal, scope, modalService, ionicDatePicker);
  //     options && options.beforeWindowShow && options.beforeWindowShow();
  //
  //     $ionicModal.show();
  //
  //     setTimeout(() => {
  //       options && options.afterWindowShown && options.afterWindowShown();
  //     });
  //   }, {
  //     scope: scope,
  //     animation: 'slide-in-up'
  //   }).then((success) => {
  //   }, (error) => {
  //     let message = error.statusText;
  //     if (error.status === -1) {
  //       message = 'Network unavailable. Please check your network settings.';
  //     }
  //     this.$ionicPopup.alert({
  //       title: 'Techpad',
  //       template: message,
  //       okText: 'OK',
  //     });
  //   });
  //   // Execute action on hide modal
  //   scope.$on('modal.hidden', () => {
  //     if ($('body').hasClass('popover-open')) {
  //       $('body').removeClass('popover-open');
  //     }
  //
  //     $('div.modal-backdrop.hide').remove();
  //     defer.resolve(model);
  //   });
  //
  //   return defer.promise;
  // }
  //

  // showStartSurveyWindow(model, item) {
  //   let scope = this.$rootScope.$new();
  //   scope.model = model;
  //   scope.item = item;
  //   this.$ionicModal.fromTemplateUrl('views/templates/startSurveyWindow.html', function($ionicModal) {
  //     scope.modal = $ionicModal;
  //     ionicModal.show();
  //   }, {
  //     scope: scope,
  //     animation: 'slide-in-up'
  //   });
  // }
  //
  // showImagePreview(imageName) {
  //   let scope = this.$rootScope.$new();
  //   scope.imageName = imageName;
  //   this.$ionicModal.fromTemplateUrl('views/templates/imagePreview.html', function($ionicModal) {
  //     scope.modal = $ionicModal;
  //     $ionicModal.show();
  //   }, {
  //     scope: scope,
  //     animation: 'slide-in-up'
  //   });
  // }

  showHospitalCodeInputWindow() {

    return new Promise<string>(async resolve => {

      const alert = await this.alertCtrl.create({
        header: 'Enter your hospital code',
        subHeader: 'Code can contain only digits and letters',
        id: 'change-hospital-code-modal',
        inputs: [
          {
            name: 'code',
            id: 'hospital-code-modal-hospital-code-input'
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              resolve(null);
            }
          },
          {
            text: 'Submit',
            cssClass: 'hospital-code-modal-submit-button button-positive',
            handler: data => {
              resolve(data.code);
            }
          }
        ]
      });

      await alert.present();
    });
  }

  // showCommentsInputWindow(comment) {
  //   let scope = this.$rootScope.$new();
  //   scope.data = {comment: comment, prev: comment};
  //
  //   let popup = this.$ionicPopup.show({
  //     template: '<textarea class="comment-area" ng-model="data.comment">',
  //     title: 'Enter the comment',
  //
  //     scope: scope,
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //         onTap() {
  //           return scope.data.prev;
  //         }
  //       },
  //       {
  //         text: '<b>Save</b>',
  //         type: 'button-positive',
  //         onTap(e) {
  //           return scope.data.comment || null;
  //         }
  //       }
  //     ]
  //   });
  //
  //   return popup;
  // }

  async showTextFieldWindow(value) {
    return new Promise<any>(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: 'Enter text',
        inputs: [
          {
            value: value,
            cssClass: "comments-text",
            type: "textarea",
            name: 'comments'
          },
        ],
        buttons: [{
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            resolve(value);
          }
        },
        {
          text: 'Save',
          cssClass: 'button-positive',
          handler: data => {
            resolve(data.comments);
          }
        }]
      });
      await alert.present();
    });
  }
  //
  // showDocumentWindow(model) {
  //   let scope = this.$rootScope.$new();
  //   scope.model = model;
  //   this.$ionicModal.fromTemplateUrl('views/templates/documentWindow.html', function($ionicModal) {
  //     scope.modal = $ionicModal;
  //     $ionicModal.show();
  //   }, {
  //     scope: scope,
  //     animation: 'slide-in-up'
  //   });
  // }
  //
  showLinkedStudiesWindow(model): Promise<LinkedStudiesModalComponentResult> {
    return new Promise<LinkedStudiesModalComponentResult>(async resolve => {
      const modal = await this.modalController.create({
        component: LinkedStudiesModalComponent,
        componentProps: _.cloneDeep({ worklist: model })
      });

      modal.onWillDismiss().then((eventDetail: OverlayEventDetail<LinkedStudiesModalComponentResult>) => {
        resolve(eventDetail.data);
      });
      await modal.present();
    });
  }
  //
  // showPatientDetails(model, updateData, callback) {
  //   let scope = this.$rootScope.$new();
  //   scope.vm = model;
  //   scope.vm.updateData = updateData;
  //   scope.vm.callback = callback;
  //   scope.vm.skipRedirecting = true;
  //
  //   this.$ionicModal.fromTemplateUrl('views/templates/patientDetailsDialog.html', function($ionicModal) {
  //     scope.modal = $ionicModal;
  //     $ionicModal.show();
  //   }, {
  //     scope: scope,
  //     animation: 'slide-in-up',
  //     backdropClickToClose: false,
  //     hardwareBackButtonClose: false
  //   });
  // }
  //
  // async showChangePasswordWindow(model) {
  //   let scope = this.$rootScope.$new();
  //   scope.model = model;
  //   await model.init();
  //
  //   let popup = this.$ionicPopup.show({
  //     template: `<span>New Password:</span>
  //                 <input type="password" ng-model="model.newPassword">
  //                 <br/>
  //                 <span>Confirm New Password:</span>
  //                 <input type="password" ng-model="model.repeatedNewPassword">
  //                 <span ng-if="model.error" class="change-password-error">{{model.error}}</span>`,
  //     title: 'Change Password',
  //     subTitle: 'Current security policy requires that you change your password. Please enter your new password.',
  //     scope: scope,
  //     buttons: [
  //       {
  //         text: '<b>Change Password</b>',
  //         type: 'button-positive',
  //         async onTap(e) {
  //           e.preventDefault();
  //           let result = await model.changePassword();
  //           if (!result.isError) {
  //             popup.close();
  //           }
  //         }
  //       }
  //     ]
  //   });
  //
  //   return popup;
  // }

  async showDialogWindow(model: ModalOptions): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create(model);
    await modal.present();
    return modal;
  }
}
