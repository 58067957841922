import { Injectable } from '@angular/core';
import { MagViewService } from './magview.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceTimeService {

  constructor(private Magview: MagViewService) { }

  updateServerTime() {
    this.Magview.getServerTime().subscribe(
      (data: any) => {
        const diff = +new Date(data.data.now) - +new Date();
        localStorage.set('serverDiff', diff);
      }, () => {
        localStorage.set('serverDiff', 0);
      });
  }

  getDiff() {
    return Number(localStorage.getItem('serverDiff')) || 0;
  }
}
