import { CommonModule, DatePipe } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  SchemaMetadata
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AdditionalInfoLabelPipe } from '../techpad-core/pipes/additional-info-label.pipe';
import { StatusColorPipe } from '../techpad-core/pipes/status-color.pipe';
import { StatusIconPipe } from '../techpad-core/pipes/status-icon.pipe';
import { StatusNamePipe } from '../techpad-core/pipes/status-name.pipe';
import { ErrorMessageModalComponent } from './components/error-message-modal/error-message-modal.component';
import { GenericHeaderContentComponent } from './components/generic-header-content/generic-header-content.component';
import { GenericWorklistDetailsComponent } from './components/generic-worklist-details/generic-worklist-details.component';
import { GenericWorklistItemContentComponent } from './components/generic-worklist-item-content/generic-worklist-item-content.component';
import { HeaderComponent } from './components/header/header.component';
import { IonInputAutocompleteModalComponent } from './components/ion-input-autocomplete-modal/ion-input-autocomplete-modal.component';
import { IonInputAutocompleteComponent } from './components/ion-input-autocomplete/ion-input-autocomplete.component';
import { IonSelectWithIconComponent } from './components/ion-select-with-icon/ion-select-with-icon.component';
import { LoginBaseComponent } from './components/login-base/login-base.component';
import { NetworkStatusCheckerComponent } from './components/network-status-checker/network-status-checker.component';
import { TimerComponent } from './components/timer/timer.component';
import { VersionComponent } from './components/version/version.component';
import { WorklistItemComponent } from './components/worklist-item/worklist-item.component';
import { WorklistListComponent } from './components/worklist-list/worklist-list.component';
import { ClickOptionButtonDirective } from './directives/click-option-button.directive';
import { GenericContentDirective } from './directives/generic-header-content.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { PhoneNumberDirective } from './directives/phone-number.directive';
import { CamelizePipe } from './pipes/camelize.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { HumanizePipe } from './pipes/humanize.pipe';

@NgModule({
  declarations: [
    IonSelectWithIconComponent,
    ErrorMessageModalComponent,
    VersionComponent,
    NumbersOnlyDirective,
    PhoneNumberDirective,
    TimerComponent,
    HeaderComponent,
    NetworkStatusCheckerComponent,
    GenericContentDirective,
    IonInputAutocompleteComponent,
    IonInputAutocompleteModalComponent,
    WorklistListComponent,
    WorklistItemComponent,
    LoginBaseComponent,
    AdditionalInfoLabelPipe,
    CamelizePipe,
    HumanizePipe,
    FilterPipe,
    StatusColorPipe,
    StatusIconPipe,
    StatusNamePipe,
    GenericHeaderContentComponent,
    ClickOptionButtonDirective,
    GenericWorklistItemContentComponent,
    GenericWorklistDetailsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
  ],
  providers: [
    DatePipe
  ],
  exports: [
    IonSelectWithIconComponent,
    ErrorMessageModalComponent,
    VersionComponent,
    PhoneNumberDirective,
    NumbersOnlyDirective,
    TimerComponent,
    HeaderComponent,
    NetworkStatusCheckerComponent,
    WorklistListComponent,
    WorklistItemComponent,
    LoginBaseComponent,
    AdditionalInfoLabelPipe,
    CamelizePipe,
    HumanizePipe,
    FilterPipe,
    StatusColorPipe,
    StatusIconPipe,
    StatusNamePipe,
    GenericHeaderContentComponent,
    ClickOptionButtonDirective,
    IonInputAutocompleteComponent,
    ClickOptionButtonDirective,
    GenericWorklistItemContentComponent,
    GenericWorklistDetailsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] as SchemaMetadata[],
})
export class CoreModule { }
