import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {applicationConfiguration} from '../../../config';
import {PingService} from '../../core/services/ping.service';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MagViewService {

  warmedUp = false;
  portalWarmedUp = false;

  constructor(private authService: AuthService,
              private pingSvc: PingService,
              private $http: HttpClient) {
  }

  getVersion() {
    return this.$http.get(this.authService.currentMagViewUrl() + '/api/configuration/getversion');
  }

  getServerTime() {
    return this.$http.get(this.authService.currentMagViewUrl() + '/api/configuration/getServerTime')
      .pipe(catchError((err) => {
        this.warmedUp = false;
        this.portalWarmedUp = false;
        throw err;
      }));
  }


  getServerTimeNotUtc() {
    return this.$http.get(this.authService.currentMagViewUrl() + '/api/configuration/GetServerTimeNotUtc')
      .pipe(catchError((err) => {
        this.warmedUp = false;
        this.portalWarmedUp = false;
        throw err;
      })).toPromise();
  }

  getInstances() {
    return this.$http.get(applicationConfiguration.apiUrl + 'instances');
  }

  pingServer(url) {
    return this.pingSvc.ping(url);
  }

  warmupPortal(url) {
    if (this.portalWarmedUp) {
      return;
    }

    return this.$http.get(url + '/initialization').subscribe(() => {
      this.portalWarmedUp = true;
    });
  }

  warmupMagviewApi(url) {
    if (this.warmedUp) {
      return;
    }

    return this.$http.get(url + applicationConfiguration.magViewApi + '/initialization').subscribe(() => {
      this.warmedUp = true;
    });
  }
}
