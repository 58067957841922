import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AjaxService {
  tokenHeaderValue = {} as any;

  constructor(private $http: HttpClient,
    private localStorage: LocalStorageService) {
  }

  buildLocalStorageCacheKey(url) {
    return 'CACHE_REQUEST::' + url;
  }

  async request(options, onSuccess, onError) {
    try {
      const response = await this.$http.request(options.method, options.url, {
        observe: 'response',
        headers: options.headers,
        body: options.body
      }).toPromise();
      onSuccess(response);
    }
    catch (error) {
      console.error(options, error);
      onError(error);
    }
  }

  loadRealResponse(options, cacheKey, defer) {
    this.request(
      options,
      (response) => {
        if (response.body) {
          const cachedData = {
            body: response.body,
            lastModified: response.headers.get('Last-Modified')
          };
          this.localStorage.setObject(cacheKey, cachedData);
        }
        defer.resolve(response.body);
      },
      (error) => {
        defer.reject(error);
      });
  }

  makeAjax(options, shouldCacheData = false) {
    return new Promise((resolve, reject) => {
      let headers = options.headers || {};
      if (options.auth !== false) {
        headers = _.extend(_.cloneDeep(this.tokenHeaderValue), headers);
      }

      options.headers = headers;
      options.contentType = options.contentType || 'application/json';

      if (shouldCacheData) {

        const cacheKey = this.buildLocalStorageCacheKey(options.url);

        const originalMethod = options.method;
        const cachedData = this.localStorage.getObject(cacheKey);

        if (cachedData != null) {
          options.method = 'HEAD';
          options.headers.IsBackground = true;

          this.request(options, (response) => {
            if (response.headers.get('Last-Modified') == cachedData.lastModified) {
              resolve(cachedData.body);
            } else {
              options.method = originalMethod;
              delete options.headers.IsBackground;
              this.loadRealResponse(options, cacheKey, { resolve, reject });
            }
          }, (error) => {
            reject(error);
          });
        } else {
          this.loadRealResponse(options, cacheKey, { resolve, reject });
        }

        return;
      }

      this.request(options, (response) => {
        if (options.method == 'HEAD') {
          resolve(response);
        } else {
          resolve(response.body);
        }
      }, (error) => {
        reject(error);
      });

    });
  }

  get(url, data = null, headers = null, auth = null, params = null) {
    return this.makeAjax({
      url,
      method: 'GET',
      data,
      headers,
      auth,
      params
    });
  }

  getAndCache(url, data = null, headers = null, auth = null, params = null) {
    return this.makeAjax({
      url,
      method: 'GET',
      data,
      headers,
      auth,
      params
    }, true);
  }

  head(url, data, headers, auth) {
    headers = headers || {};

    headers.IsBackground = true;

    return this.makeAjax({
      url,
      method: 'HEAD',
      data,
      headers,
      auth
    });
  }

  post(url, body, headers = null) {
    return this.makeAjax({
      url,
      method: 'POST',
      body,
      headers
    });
  }

  put(url, data, headers) {
    return this.makeAjax({
      url,
      method: 'PUT',
      data,
      headers
    });
  }

  delete(url, headers) {
    return this.makeAjax({
      url,
      method: 'DELETE',
      headers
    });
  }
}
