import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { GenericHeaderContentItem } from '../models/generic-header-content-item';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private shouldShowHeader$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  shouldShowHeader = this.shouldShowHeader$.asObservable();

  private headerContent$: BehaviorSubject<GenericHeaderContentItem | null> = new BehaviorSubject(null);

  headerContent = this.headerContent$.asObservable();

  username$: Subject<string> = new Subject();

  username = this.username$.asObservable();

  logoutEvent: EventEmitter<void> = new EventEmitter<void>();

  private shouldShowBackButton$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  shouldShowBackButton = this.shouldShowBackButton$.asObservable();

  private backButtonTarget$: BehaviorSubject<any[] | null> = new BehaviorSubject(null);

  backButtonTarget = this.backButtonTarget$.asObservable();

  constructor() { }

  updateShouldShowHeader(shouldShowHeader: boolean) {
    this.shouldShowHeader$.next(shouldShowHeader);
  }

  updateHeaderContent(headerContent: GenericHeaderContentItem) {
    this.headerContent$.next(headerContent);
  }

  updateUsername(username: string) {
    this.username$.next(username);
  }

  updateShouldShowBackButton(shouldShowBackButton: boolean) {
    this.shouldShowBackButton$.next(shouldShowBackButton);
  }

  updateBackButtonTarget(backButtonTarget: any[]) {
    this.backButtonTarget$.next(backButtonTarget);
  }

  logout() {
    this.logoutEvent.emit();
  }
}
