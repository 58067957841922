import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild
} from '@angular/core';
import { IonContent } from '@ionic/angular';
import { WorklistRealtimeService } from '../../services/worklist-realtime.service';

@Component({
  selector: 'app-worklist-list',
  templateUrl: './worklist-list.component.html',
  styleUrls: ['./worklist-list.component.scss'],
})
export class WorklistListComponent implements OnInit, OnDestroy {

  @ViewChild('content')
  public content: IonContent;

  @Input()
  worklistItemType: Type<any>;

  @Input()
  worklists: any[] = [];

  @Input()
  trackByFunction: (index: number, item: any) => string = (index, item) => item.id;

  @Output()
  dataRefresh: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  loadMore: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  itemCreated: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  itemUpdated: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  itemDeleted: EventEmitter<any> = new EventEmitter<any>();

  activatedEffects: any = {} ;

  constructor(private worklistRealtimeService: WorklistRealtimeService) {

  }

  hardRefresh($event) {
    this.dataRefresh.emit($event);
  }

  onLoadMore($event) {
    this.loadMore.emit($event);
  }

  ngOnInit(): void {
    this.worklistRealtimeService.worklistCreated.subscribe(x => {
      this.itemCreated.next(x);
    });
    this.worklistRealtimeService.worklistUpdated.subscribe(x => {
      this.itemUpdated.next(x);
    });
    this.worklistRealtimeService.worklistDeleted.subscribe(x => {
      this.itemDeleted.next(x);
    });
  }

  activateEffectOnItem(id: number, effectClass: string, duration: number) {
    let prop = 'item_' + id;
    this.activatedEffects[prop] = effectClass;

    setTimeout(() => {
      this.activatedEffects[prop] = null;
    }, duration);
  }

  ngOnDestroy(): void {
  }
}
