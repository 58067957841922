import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';
import { SLIDING_SIDE_END } from '../../../../modules/core/constants/ionic.constants';
import { ComponentContent } from '../../../../modules/core/models/component-content.model';
import { LoadingService } from '../../../../modules/core/services/loading.service';
import { ModalService } from '../../../../modules/core/services/modal.service';
import { WorklistResourcesProvider } from '../../contracts/worklist-resources.provider';
import { WorklistStatusProvider } from '../../contracts/worklist-status.provider';
import { WorklistTagsProvider } from '../../contracts/worklist-tags.provider';

const WORKLIST_ION_ITEM_SLIDING_PREFIX = 'worklist-item-sliding-';

@Component({
  selector: 'generic-worklist-item-content',
  templateUrl: './generic-worklist-item-content.component.html',
  styleUrls: ['./generic-worklist-item-content.component.scss'],
})
export class GenericWorklistItemContentComponent implements ComponentContent, OnDestroy {

  @Input()
  data: any;

  @Input()
  canEdit: boolean;

  @Input()
  leftHeader: any;

  @Input()
  leftSubHeader: any;

  @Input()
  stuffName: any;

  @Input()
  stuffMessage: any;

  @Input()
  middleHeader: any;

  @Input()
  rightHeader: any;

  @Input()
  showChangeTagsButton: boolean = true;

  @Input()
  showChangeStatusButton: boolean = true;

  @Input()
  showDeleteButton: boolean = true;

  @Input()
  showResource: boolean = true;

  @Input()
  tagColor: any;

  @Input()
  tagBackgroundColor: any;

  @Input()
  tags: string[];

  @Input()
  listMessageColor: any;

  @Output()
  deleteWorklist: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changeListMessage: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  worklistItemClick: EventEmitter<any> = new EventEmitter<any>();

  isOnline: boolean = true;

  constructor(private elementRef: ElementRef,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private worklistStatusProvider: WorklistStatusProvider,
    private worklistTagsProvider: WorklistTagsProvider,
    private worklistResourcesProvider: WorklistResourcesProvider) {
  }

  async onWorklistItemClick($event) {
    this.worklistItemClick.emit($event);
  }

  async onChangeTagsButtonClick($event) {
    if (await this.worklistTagsProvider.isAllowedToChangeTags(this.data)) {
      const { tag } = await this.modalService.showWorklistChangeTags(this.data, $event) || {};
      if (tag) {
        this.toggleTag(tag);
      }
    }
  }

  private async toggleTag(tag) {
    await this.loadingService.showLoadingScreen({ message: 'Applying tag change...' });
    try {
      await this.worklistTagsProvider.saveToggleTag(this.data.id, tag, this.data);
    } catch (error) {
      this.modalService.showErrorAlert(this.modalService.parseError(error) || 'Error happened on applying tag change');
      console.error(error);
    } finally {
      this.loadingService.hideLoadingScreen();
    }
  }

  async changeStatusPopover($event) {
    if (await this.worklistStatusProvider.isAllowedToChangeStatus(this.data)) {
      this.worklistStatusProvider.changeStatusPopover(this.data, $event);
    }
  }

  async deleteItem($event) {
    this.deleteWorklist.emit($event);
  }

  async openChangeListMessagePopover($event) {
    this.changeListMessage.emit($event);
  }

  async openChangeResourcePopover($event) {
    if (await this.worklistResourcesProvider.isAllowedToChangeResources(this.data)) {
      $event.stopPropagation();
      await this.loadingService.showLoadingScreen({ message: 'Loading available resources...' });
      try {
        const availableResources = await this.worklistResourcesProvider.getAvailableResources(this.data.id);
        this.modalService.showWorklistChangeResource(
          {
            id: this.data.id,
            resource: this.data.resource
          },
          $event,
          availableResources
        ).then(res => this.applyResource(res));
      } catch (error) {
        console.error(error);
      } finally {
        await this.loadingService.hideLoadingScreen();
      }
    }
  }

  private async applyResource(res) {
    if (res.isResourceChanged) {
      await this.loadingService.showLoadingScreen({ message: 'Applying resource...' });
      try {
        await this.worklistResourcesProvider.saveUpdateResource(this.data.id, res.selectedResource);
        this.data.resource = res.selectedResource;
      } catch (error) {
        this.modalService.showErrorAlert(this.modalService.parseError(error) || 'Error happened on applying resource values');
        console.error(error);
      } finally {
        await this.loadingService.hideLoadingScreen();
      }
    }
  }

  async openOptions($event) {
    $event.stopPropagation();
    const worklistIonItemSlidingElement: IonItemSliding = this.getWorklistIonItemSlidingElement($event);
    await worklistIonItemSlidingElement.open(SLIDING_SIDE_END);
  }

  private getWorklistIonItemSlidingElement($event): any {
    //return $event.target.parentElement.parentElement;
    const worklistIonItemSlidingId = `${WORKLIST_ION_ITEM_SLIDING_PREFIX}${this.data.id}`;
    const worklistIonItemSlidingElement = this.elementRef.nativeElement.children[worklistIonItemSlidingId];
    if (!worklistIonItemSlidingElement) {
      throw new Error(`DOM element with id '${worklistIonItemSlidingId}' was not found`);
    }
    return worklistIonItemSlidingElement;
  }

  ngOnDestroy(): void {
  }
}
