import { Injectable } from '@angular/core';
import { WorklistStatusProvider } from '../../core/contracts/worklist-status.provider';
import { LoadingService } from '../../core/services/loading.service';
import { ModalService } from '../../core/services/modal.service';
import { WorklistLockService } from './worklist-lock.service';
import { WorklistOwnershipService } from './worklist-ownership.service';
import { WorklistService } from './worklist.service';

@Injectable({
    providedIn: 'root'
})
export class WorklistStatusProviderService implements WorklistStatusProvider {

    constructor(private modalService: ModalService,
        private Worklist: WorklistService,
        private loadingService: LoadingService,
        private worklistOwnershipService: WorklistOwnershipService,
        private worklistLockService: WorklistLockService) {
    }

    async isAllowedToChangeStatus(worklist) {
        const isLocked = await this.worklistLockService.checkIfLocked(worklist.id);
        return !isLocked;
    }

    async changeStatusPopover(item, $event) {
        if (await this.worklistOwnershipService.isOwnerOrConfirmedToTakeOwnership(item)) {
            const { status } = await this.modalService.showWorklistChangeStatus(item, $event);
            await this.changeStatus(item, status);
        }
    }

    private async changeStatus(item, status) {
        await this.loadingService.showLoadingScreen({ message: 'Changing status...' });
        try {
            await this.Worklist.ownWorklist(item.id);
            await this.Worklist.updateStatus(item.id, status);
            item.status = status;
        } catch (ex) {
            this.modalService.showAlert((ex && ex.error.Message && ex.error.Message) || ex || "Error happened on changing status");
        } finally {
            this.loadingService.hideLoadingScreen();
        }
    }
}