import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-dashboard-change-status',
  templateUrl: './dashboard-change-status-modal.component.html',
  styleUrls: ['./dashboard-change-status-modal.component.scss'],
})
export class DashboardChangeStatusModalComponent implements OnInit {

  @Input()
  allowedWorklistChangeStatuses: any[];

  @Input()
  id: number;

  @Input()
  item: any;

  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  changeStatus(status: any) {
    this.modalController.dismiss({item: this.item, status });
  }

}
