import { Pipe, PipeTransform } from '@angular/core';
import { WorklistStatuses } from '../enums/worklist-stauses.enum';

@Pipe({
  name: 'additionalInfoLabel'
})
export class AdditionalInfoLabelPipe implements PipeTransform {

  transform(additionalInfo: string): string {
    /*switch (additionalInfo) {
      case WorklistAddditionalInfo.ReleasePatient.infoCode:
        return WorklistAddditionalInfo.ReleasePatient.infoAlias;
      case WorklistAddditionalInfo.AdditionalImaging.infoCode:
        return WorklistAddditionalInfo.AdditionalImaging.infoAlias;
      case WorklistAddditionalInfo.Other.infoCode:
        return WorklistAddditionalInfo.Other.infoAlias;
      default:
        return '';
    }*/
    return '';
  }

}
