import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { SubscriptionService } from 'src/app/modules/core/services/subscription.service';
import { CONSTANT_STRINGS } from '../../../../modules/techpad-core/constants/constants';
import { LOOKUPS } from '../../../../modules/techpad-core/constants/lookups';
import { AuthService } from '../../../../modules/techpad-core/services/auth.service';
import { ModalService } from '../../../../modules/core/services/modal.service';
import { TechpadConfigService } from '../../../../modules/techpad-core/services/techpad-config.service';
import { WorklistFilterSettingsService } from '../../../../modules/techpad-core/services/worklist-filter-settings.service';
import { WorklistService } from '../../../../modules/techpad-core/services/worklist.service';

@Component({
  templateUrl: './worklist-filter-modal.component.html',
  styleUrls: ['./worklist-filter-modal.component.scss'],
})
export class WorklistFilterModalComponent implements OnInit, OnDestroy {

  @Input()
  filters: any;

  @Input()
  examTypes = LOOKUPS.examTypes;

  @Input()
  locations: any[] = [];

  model: any;
  constantStrings = CONSTANT_STRINGS;

  visitTypes = LOOKUPS.visitTypes;
  allStatusGroups = LOOKUPS.allStatusGroups;

  tags: any[];
  statuses: any[] = [];

  statusGroups: any[];

  techpadSettings: any;

  componentSubscriptions: Subscription = new Subscription();
  showAppointmentTime: any = this.techpadConfig.settings.showAppointmentTime;

  numberOfLastDaysToShowOptions = [
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 5, label: '5' },
    { value: 7, label: '7' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 365, label: '365' },
    { value: -1, label: 'All' }
  ];

  selectedVisitTypes: any = {};
  selectedExamTypes: any = {};
  selectedTags: any = {};
  selectedSortingOptions: any = {};

  constructor(public techpadConfig: TechpadConfigService,
              private authService: AuthService,
              private modalController: ModalController,
              private modalService: ModalService,
              private WorklistFilterSettings: WorklistFilterSettingsService,
              private Worklist: WorklistService,
              private subscriptionService: SubscriptionService) {
  }

  async ngOnInit() {

    this.model = _.cloneDeep(this.filters);

    this.tags = this.techpadConfig.settings.tags && this.techpadConfig.settings.tags.map(tag => tag.label) || [];
    // TODO: 311 - brought from old techpad, need to fix later
    // this.showAppointmentTime = this.modalService.showAppointmentTime;
    // 311 ----------

    this.componentSubscriptions.add(this.techpadConfig.techpadSettings.subscribe(value => {
      this.techpadSettings = value;
    }));

    /*
    if (!this.techpadSettings.groupExamTypeWorklistFilter) {
      this.examTypes = _.map((await this.Worklist.getExamTypes()), 'value');
    }

    this.Worklist.getLocations()
      .subscribe((resp) => {
        this.locations = resp as any[];
        this.locations.unshift({
          key: this.constantStrings.allOption.toLowerCase(),
          value: this.constantStrings.allOption
        });

      }, () => {
        this.locations = [];
      });*/

    const displayStatusesConfigs: any = await this.Worklist.getDisplayStatusesConfigs();
    this.statuses = displayStatusesConfigs;

    _.forEach(this.statuses, (status) => {
      status.shouldFilter = this.model.statuses[_.camelCase(status.status)] === true;
    });

    this.statusGroups = this.getAllowedStatusGroups();
    this.statusGroups.forEach((group) => {
      group.value = this.model.statuses[_.camelCase(group.key)];
    });

    this.visitTypes.forEach(x => {
      this.selectedVisitTypes[x.code] = _.includes(this.model.visitTypes, x.code);
    });
    this.examTypes.forEach(x => {
      this.selectedExamTypes[x] = _.includes(this.model.examTypes, x);
    });
    this.selectedExamTypes[this.constantStrings.allOption.toLowerCase()] = _.includes(this.model.examTypes, this.constantStrings.allOption.toLowerCase());
    this.tags.forEach(x => {
      this.selectedTags[x] = _.includes(this.model.tags, x);
    });
    this.selectedTags[this.constantStrings.noTagsOption] = this.model.showNoTags;
    this.selectedTags[this.constantStrings.allOption.toLowerCase()] = this.model.showAllTags;

    if (this.model.sortingOptions && this.model.sortingOptions.length) {
      this.selectedSortingOptions = {};
      this.selectedSortingOptions[this.model.sortingOptions[0]] = true;
    }
  }

  getAllowedStatusGroups() {
    const userRoles = this.authService.getRoles();
    return _.filter(this.allStatusGroups, (group) => {
      return !group.onlyForRoles || _.find(group.onlyForRoles, (role) => {
        return userRoles.indexOf(role) != -1;
      });
    });
  }

  onTodayOnlyCheckChanged() {
    if (this.model.ShowTodayExamsOnly) {
      this.model.ShowNLastDaysExams = false;
    }
  }

  onShowNLastDaysCheckChanged() {
    if (this.model.ShowNLastDaysExams) {
      this.model.ShowTodayExamsOnly = false;
    }
  }

  async onChecklistItemChange(changingKey, model) {

    if (changingKey === this.constantStrings.allOption.toLowerCase()) {
      Object.keys(model).filter(key => key !== this.constantStrings.allOption.toLowerCase()).forEach(x => {
        model[x] = !model[this.constantStrings.allOption.toLowerCase()];
      });
    }
    else if (!model[changingKey]) {
      const allOtherItemsSelected = Object.keys(model)
        .filter(key => key !== this.constantStrings.allOption.toLowerCase() && key !== changingKey)
        .every(key => model[key]);
      if (allOtherItemsSelected) {
        model[this.constantStrings.allOption.toLowerCase()] = true;
      }
    }
    else {
      model[this.constantStrings.allOption.toLowerCase()] = false;
    }
  }

  toggleSortingOptions(sortOption) {
    Object.keys(this.selectedSortingOptions).forEach(key => {
      if (sortOption !== key) {
        this.selectedSortingOptions[key] = false;
      }
    });
  }

  checkQuickFilterValidation() {
    if (!(this.model && this.model.showAllExams) && !this.model.showMyExamsOnly && !this.model.showTodayExamsOnly) {
      this.modalService.showAlert('Please select at least one quick filter', function () {
        this.filters.showMyExamsOnly = true;
      });
      return false;
    }
    return true;
  }

  async saveSettings() {

    this.model.statuses = {};
    this.statusGroups.forEach((statusGroup) => {
      statusGroup.statuses.forEach((status) => {
        this.model.statuses[_.camelCase(status)] = statusGroup.value;
      });
    });

    this.model.visitTypes = Object.keys(this.selectedVisitTypes)
      .filter(key => this.selectedVisitTypes[key]);

    this.model.examTypes = Object.keys(this.selectedExamTypes)
      .filter(key => this.selectedExamTypes[key]);

    this.model.tags = Object.keys(this.selectedTags)
      .filter(key => key !== this.constantStrings.allOption.toLowerCase() && key !== this.constantStrings.noTagsOption && this.selectedTags[key])
      .filter(key => this.selectedTags[key]);

    this.model.showAllTags = this.selectedTags[this.constantStrings.allOption.toLowerCase()];
    this.model.showNoTags = this.selectedTags[this.constantStrings.noTagsOption];

    Object.keys(this.selectedSortingOptions).forEach(key => {
      if (this.selectedSortingOptions[key]) {
        this.model.sortingOptions = [key];
      }
      return;
    });

    await this.WorklistFilterSettings.saveMySettings(this.model);

    this.modalController.dismiss(this.model);
  }

  cancelChanges() {
    this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribe(this.componentSubscriptions);
  }

}
