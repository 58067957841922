import { Injectable } from '@angular/core';
import { WorklistStatusProvider } from '../../../../app/modules/core/contracts/worklist-status.provider';

@Injectable()
export class NextWorklistStatusProviderService implements WorklistStatusProvider {
  changeStatusPopover(item, $event): Promise<void> {
    return Promise.resolve(undefined);
  }

  isAllowedToChangeStatus(worklist): Promise<boolean> {
    return Promise.resolve(true);
  }
}
