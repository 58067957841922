import { Location } from '@angular/common';
import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthProvider } from '../../contracts/auth.provider';
import { GenericContentDirective } from '../../directives/generic-header-content.directive';
import { ComponentContent } from '../../models/component-content.model';
import { GenericHeaderContentItem } from '../../models/generic-header-content-item';
import { HeaderService } from '../../services/header.service';
import { SignalrService } from '../../services/signalr.service';
import { SubscriptionService } from '../../services/subscription.service';
import { RealtimeConnectionService } from '../../services/realtime.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  //@ViewChild(GenericContentDirective, { static: false })
  //appHeaderContentDirective: GenericContentDirective;

  private appHeaderContentDirective: GenericContentDirective;
  @ViewChild(GenericContentDirective) set content(content: GenericContentDirective) {
    if (content) { // initially setter gets called with undefined
      this.appHeaderContentDirective = content;
      this.onAppHeaderContentDirectiveReady();
    }
  }

  shouldShowHeader: boolean;

  shouldShowBackButton: boolean = true;

  backButtonTarget: any[];

  isOnline: boolean;

  username: string;

  headerContent: GenericHeaderContentItem;

  private subscription: Subscription = new Subscription();

  constructor(private router: Router,
    private headerService: HeaderService,
    private signalrService: SignalrService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private subscriptionService: SubscriptionService,
    private location: Location,
    private authProvider: AuthProvider) {
  }

  ngOnInit() {

    this.headerService.shouldShowHeader.subscribe({
      next: async (shouldShowHeader) => {
         this.shouldShowHeader = shouldShowHeader;
      }
    });

    this.username = this.authProvider.getDisplayName();
  }

  onAppHeaderContentDirectiveReady() {
    this.subscription.add(this.signalrService.isOnline.subscribe({
      next: (isOnline: boolean) => {
        this.isOnline = isOnline;
      }
    }));

    this.subscription.add(this.headerService.username.subscribe({
      next: (username) => {
        this.username = username;
      }
    }));

    this.subscription.add(this.headerService.shouldShowBackButton.subscribe({
      next: (shouldShowBackButton) => {
        this.shouldShowBackButton = shouldShowBackButton;
      }
    }));

    this.subscription.add(this.headerService.backButtonTarget.subscribe({
      next: (backButtonTarget) => {
        this.backButtonTarget = backButtonTarget;
      }
    }));

    this.subscription.add(this.headerService.headerContent.subscribe({
      next: (headerContent) => {
        //console.log('--->> onHeaderContentUpdate()', headerContent);
        this.headerContent = headerContent;
        this.updateContentComponent(this.headerContent);
      },
    }));
  }

  goBack() {
    if (this.headerService.backButtonTarget) {
      this.router.navigate(this.backButtonTarget);
    }
    else {
      this.location.back();
    }
  }

  async logout(): Promise<void> {
    this.headerService.logout();
  }

  updateContentComponent(headerContentItem: GenericHeaderContentItem): void {
    //console.log('--->> updateContentComponent()');
    if (headerContentItem) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(headerContentItem.component);
      const viewContainerRef = this.appHeaderContentDirective.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent<ComponentContent>(componentFactory);
      componentRef.instance.data = headerContentItem.data;
      componentRef.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    //console.log('--->> ngOnDestroy()');
    this.subscriptionService.unsubscribe(this.subscription);
  }
}
