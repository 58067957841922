import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PingService {
  lastPing = null;

  constructor(private $http: HttpClient) {
  }

  ping(url) {
    if (this.lastPing) {
      return this.lastPing;
    }

    this.lastPing = this.$http.get(_.trim(url, '/') + '/api/configuration/getServerTime').toPromise()
      .then(() => {
        setTimeout(() => {
          this.lastPing = null;
        }, 5000);
      }, (error) => {
        this.lastPing = null;
        throw error;
      });

    return this.lastPing;
  }

  async pingTest(url, times) {
    const startTime = new Date();

    const timesToPing = times || 5;

    const pingResults = [];
    let index = 0;
    while (pingResults.length < timesToPing) {
      index++;
      const appendVariable = index % 3 === 0 ? '1' : 'testPing2';
      const urlToConnect = `${_.trim(url, '/')}/api/configuration/getServerTime?_=${appendVariable}&indexRequest=${index}`;

      pingResults.push(this.$http.get(urlToConnect).toPromise());
      console.log(`Request to url ${urlToConnect} made`);
    }

    await Promise.all(pingResults);
    const pingFinished = new Date();
    const duration = moment(pingFinished).diff(startTime);
    const message = `v2 Pinged the server ${times} time(s) and took ${duration}ms`;

    const urlToLog = `${_.trim(url, '/')}/api/logging/logMessage`;
    this.$http.post(urlToLog, {message}).subscribe(() => {
    });

    return {
      startTime,
      finished: pingFinished,
      duration,
      message
    };
  }
}
