import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/membership/services/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/membership/next-membership.module').then(m => m.NextMembershipModule)
  },
  {
    path: 'worklist',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/worklist-order/next-worklist.module').then(m => m.VbWorklistPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
})
export class AppNextRoutingModule { }
