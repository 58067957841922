import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { EventEmitter, Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class RealtimeConnectionService {

  onConnected: EventEmitter<any> = new EventEmitter<any>();

  connectionOptions: any;
  connection: HubConnection;
  public connected: boolean = false;
  initialized: boolean = false;

  connectingPromise = null;
  initializingPromise = null;
  connectionErrors = 0;

  reconnectingInterval = 5000;

  isConnected() {
    return this.connected;
  }

  async init(connectionUrl: string, accessTokenFactory: () => string) {
    if (this.initialized) {
      throw new Error('Connection is already initialized');
    }

    if (this.initializingPromise) {
      return this.initializingPromise;
    }

    this.connectionOptions = {
      url: connectionUrl,
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: accessTokenFactory
    };

    this.initializingPromise = new Promise((resolve, reject) => {
      const connectionBuilder = new HubConnectionBuilder()
        .withUrl(this.connectionOptions.url, this.connectionOptions);

      this.connection = connectionBuilder.build();

      this.connection.onclose((error) => {
        this.connected = false;
        // console.log('Connection on closed. Try to connect again', error);
        // setTimeout(() => this.tryConnect(), this.reconnectingInterval);
      });

      return this.tryConnect().then(() => {
        resolve();
      });
    });

    return this.initializingPromise;
  }

  private async tryConnect() {
    return this.connection.start().then(() => {
      this.connected = true;
      this.initialized = true;
      this.connectionErrors = 0;

      console.log('Handle realtime service reconnect: success');
      this.onConnected.emit();
    }).catch((error) => {
      this.connectionErrors++;

      console.error('Error while connecting to real time hub', error);
      setTimeout(async () => {
        // if (this.connectionErrors >= 3) {
        //   this.connectionOptions.transport = HttpTransportType.LongPolling;
        // }

        await this.tryConnect();
      }, 5000);
    });
  }

  async invoke(messageCommand, messageData?: any[]) {
    await this.connectingPromise;

    if (this.connected) {
      try {
        const result = await this.connection.invoke('InvokeMethod', messageCommand, messageData);
        return result.result;
      } catch (error) {
        console.log('Error while invoking server ', messageCommand, messageData);
        throw error;
      }
    }
  }

  on(messageCommand, callback) {
    if (this.connection) {
      this.connection.on(messageCommand, callback);
    } else {
      throw new Error('Connections is not established');
    }
  }

  off(messageCommand, callback) {
    if (this.connection) {
      this.connection.off(messageCommand, callback);
    } else {
      throw new Error('Connections is not established');
    }
  }

  async disconnect() {
    if (this.connection && this.connected) {
      return this.connection.stop().then(() => {
        this.connected = false;
      });
    } else {
      throw new Error('Connections is not established');
    }
  }

  connect() {
    if (this.connected) {
      throw new Error('Connection is already established');
    }

    return this.tryConnect();
  }
}
