import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as _ from 'lodash';
import { WorklistService } from '../../../../modules/techpad-core/services/worklist.service';
import { ModalService } from '../../../../modules/core/services/modal.service';
import DateTimeFormat = Intl.DateTimeFormat;

export interface LinkedStudiesModalComponentResult {
  isSaved: boolean;
}

@Component({
  selector: 'app-linked-studies-modal',
  templateUrl: './linked-studies-modal.component.html',
  styleUrls: ['./linked-studies-modal.component.scss'],
})
export class LinkedStudiesModalComponent implements OnInit {

  @Input()
  worklist: any;

  isShowPrimaryValidation = false;

  sides = [{
    key: 'L',
    value: 'Left'
  }, {
    key: 'R',
    value: 'Right'
  }, {
    key: 'B',
    value: 'Both'
  }];

  studies: any[];

  dateTimeConstants = DateTimeFormat;

  readonly activeColor = 'success';
  readonly inactiveColor = 'light';

  constructor(private worklistService: WorklistService,
    private modalController: ModalController,
    private modalService: ModalService) {
  }

  ngOnInit() {
    this.worklistService.getSameDayStudies(this.worklist.id).subscribe(resp => {
      this.studies = resp;

      this.studies.forEach(study => {
        if (study.linkedTo) {
          study.isLinked = true;
          const primary = this.studies.find(x => x.sDate === study.linkedTo);
          primary.isPrimary = true;
        }

        if (study.sDate === this.worklist.sDate) {
          study.isMain = true;
        }
      })
    });
  }

  getSide(sideKey) {
    const side = this.sides.find(x => x.key === sideKey);

    const sideValue = side && side.value;
    return sideValue ? ` - ${sideValue}` : '';
  }

  saveLinked() {
    const primary = this.studies.find(x => x.isPrimary);
    const hasLinked = this.studies.some(x => x.isLinked);
    if (hasLinked && !primary) {
      this.isShowPrimaryValidation = true;
    } else {
      this.studies.forEach(study => { study.linkedTo = study.isLinked ? primary.sDate : null });
      this.worklistService.saveLinkedStudies(this.worklist.id, this.studies).subscribe(() => {
        const resultModel: LinkedStudiesModalComponentResult = {
          isSaved: true,
        };
        this.hide(resultModel);
      });
    }
  }

  togglePrimary(study) {
    study.isPrimary = !study.isPrimary;
    if (study.isPrimary) {
      study.isLinked = false;
      this.studies
        .filter(x => x !== study)
        .forEach(x => {
          x.isPrimary = false;
          x.isLinked = true;
        });
    } else {
      this.studies.forEach(x => {
        x.isPrimary = false;
        x.isLinked = false;
      });
    }
    this.isShowPrimaryValidation = false;
  }

  toggleLinked(study) {
    if (this.studies.length === 2) {
      this.studies.forEach(x => {
        x.isPrimary = !x.isPrimary;
        x.isLinked = !x.isLinked;
      });
    } else {
      study.isLinked = !study.isLinked;
      if (study.isLinked) {
        study.isPrimary = false;
      }
      if (!this.studies.some(x => x.isPrimary)) {
        const notDefined = this.studies.find(x => !x.isLinked);
        if (notDefined) {
          notDefined.isPrimary = true;
        } else if (this.studies.every(x => x.isLinked)) {
          this.modalService.showAlert('Cannot set all studies as linked. There must be a primary study', () => {
            study.isPrimary = true;
            study.isLinked = false;
          }, 'link-studies-no-primary-error');
        }
      }
      if (this.studies.some(x => x.isPrimary) && !this.studies.some(x => x.isLinked)) {
        const primary = this.studies.find(x => x.isPrimary);
        this.togglePrimary(primary);
      }
    }
  }

  hide(resultModel: LinkedStudiesModalComponentResult) {
    this.modalController.dismiss(resultModel);
  }
}
