import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MAXIMUM_IONIC_COL_SIZE } from '../../constants/ionic.constants';

@Component({
  selector: 'app-login-base',
  templateUrl: './login-base.component.html',
  styleUrls: ['./login-base.component.scss'],
})
export class LoginBaseComponent implements OnInit {
  @Input() usernameLabel = 'Username';
  @Input() passwordLabel = 'Password';
  @Input() usernameLabelSize = 4;
  @Input() passwordLabelSize = 4;
  @Input() loginButtonClasses = '';
  @Input() username = '';
  @Output() usernameChange = new EventEmitter<string>();
  @Input() password = '';
  @Output() passwordChange = new EventEmitter<string>();

  @Output() login = new EventEmitter<KeyboardEvent>();

  usernameInputSize = MAXIMUM_IONIC_COL_SIZE - this.usernameLabelSize;
  passwordInputSize = MAXIMUM_IONIC_COL_SIZE - this.passwordLabelSize;

  constructor() { }

  ngOnInit() { }

  onLogin($event: any) {
    this.login.emit($event);
  }

  onUsernameChange($event: CustomEvent) {
    this.usernameChange.emit(this.username);
  }

  onPasswordChange($event: CustomEvent) {
    this.passwordChange.emit(this.password);
  }
}
