import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as dateTimeConstants from '../../constants/date-time.constants';

@Component({
  selector: 'app-error-message-modal',
  templateUrl: './error-message-modal.component.html',
  styleUrls: ['./error-message-modal.component.scss'],
})
export class ErrorMessageModalComponent implements OnInit {
  @Input() error: any;
  @Input() type: string;
  @Input() version: string;
  @Input() stamp: Date;
  dateTimeConstants = dateTimeConstants;

  constructor(private modalCtrl: ModalController) {
  }

  ngOnInit() {
  }

  async close() {
    await this.modalCtrl.dismiss();
  }
}
