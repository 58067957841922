import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit, OnDestroy {
  interval: any;
  @Input() startTime: any;
  seconds: number | string;
  minutes: number | string;
  hours: number | string;

  constructor() { }

  ngOnDestroy(): void {
    window.clearInterval(this.interval);
  }

  ngOnInit() {
    this.calculateTimeUnits();
    this.interval = setInterval(() => {
      this.calculateTimeUnits();
    }, 1000);
  }

  private calculateTimeUnits() {
    let millis = 0;
    if (this.startTime !== undefined){
      millis = +new Date() - +new Date(this.startTime);
    }

    const seconds = Math.floor((millis / 1000) % 60);
    const minutes = Math.floor(((millis / (60000)) % 60));
    const hours = Math.floor(millis / 3600000);

    // add leading zero if number is smaller than 10
    this.seconds = seconds < 10 ? '0' + seconds : seconds;
    this.minutes = minutes < 10 ? '0' + minutes : minutes;
    this.hours = hours < 10 ? '0' + hours : hours;
  }
}
