import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NextLoginComponent } from './pages/next-login/next-login.component';

const routes: Routes = [
  {
    path: '',
    component: NextLoginComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})
export class NextMembershipRoutingModule { }
