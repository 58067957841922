import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Resource } from 'src/app/modules/core/models/resource.model';

@Component({
    templateUrl: './worklist-change-resource.component.html',
    styleUrls: ['./worklist-change-resource.component.scss'],
})
export class WorklistChangeResourceComponent implements OnInit {

    @Input()
    data: any;

    @Input()
    initialModel: any;

    @Input()
    availableResources: Resource[];

    constructor(private popoverController: PopoverController) {
    }

    ngOnInit() {
        this.data.selectedResource = this.data.resource || '';
    }

    toggleResourceForWorklist(resource) {
        if (this.data.selectedResource === resource.label) {
            this.data.selectedResource = '';
        } else {
            this.data.selectedResource = resource.label;
        }
        this.popoverController.dismiss();
    }

    done() {
        this.popoverController.dismiss();
    }
}