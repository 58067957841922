import { Pipe, PipeTransform } from '@angular/core';
import { TechpadConfigService } from '../services/techpad-config.service';
import { WorklistStatuses } from '../enums/worklist-stauses.enum';

@Pipe({
  name: 'statusName'
})
export class StatusNamePipe implements PipeTransform {
  techpadSettings: any;

  constructor(techpadConfig: TechpadConfigService) {
    this.techpadSettings = techpadConfig.settings;
  }

  transform(status: string): string {
    switch (status) {
      case WorklistStatuses.New:
        try {
          const label = this.techpadSettings.worklistStatusesConfigs.find(status => status.status == WorklistStatuses.New).label;
          return label;
        } catch (error) {
          return 'Survey Assigned';
        }
      case WorklistStatuses.SurveyStarted:
        try {
          const label = this.techpadSettings.worklistStatusesConfigs.find(status => status.status == WorklistStatuses.SurveyStarted).label;
          return label;
        } catch (error) {
          return 'Survey Started';
        }
      case WorklistStatuses.SurveyFinished:
        try {
          const label = this.techpadSettings.worklistStatusesConfigs.find(status => status.status == WorklistStatuses.SurveyFinished).label;
          return label;
        } catch (error) {
          return 'Survey Completed';
        }
      case WorklistStatuses.ExamWaiting:
        try {
          const label = this.techpadSettings.worklistStatusesConfigs.find(status => status.status == WorklistStatuses.ExamWaiting).label;
          return label;
        } catch (error) {
          return 'Waiting for exam';
        }
      case WorklistStatuses.ExamIncomplete:
        try {
          const label = this.techpadSettings.worklistStatusesConfigs.find(status => status.status == WorklistStatuses.ExamIncomplete).label;
          return label;
        } catch (error) {
          return 'Exam Incomplete';
        }
      case WorklistStatuses.ExamInProgress:
        try {
          const label = this.techpadSettings.worklistStatusesConfigs.find(status => status.status == WorklistStatuses.ExamInProgress).label;
          return label;
        } catch (error) {
          return 'Exam Started';
        }
      case WorklistStatuses.ExamPaused:
        try {
          const label = this.techpadSettings.worklistStatusesConfigs.find(status => status.status == WorklistStatuses.ExamPaused).label;
          return label;
        } catch (error) {
          return 'Exam Paused';
        }
      case WorklistStatuses.ExamStopped:
        try {
          const label = this.techpadSettings.worklistStatusesConfigs.find(status => status.status == WorklistStatuses.ExamStopped).label;
          return label;
        } catch (error) {
          return 'Exam Ended';
        }
      case WorklistStatuses.Finished:
        try {
          const label = this.techpadSettings.worklistStatusesConfigs.find(status => status.status == WorklistStatuses.Finished).label;
          return label;
        } catch (error) {
          return 'Exam Finished';
        }
      case WorklistStatuses.TakeExam:
        try {
          const label = this.techpadSettings.worklistStatusesConfigs.find(status => status.status == WorklistStatuses.TakeExam).label;
          return label;
        } catch (error) {
          return 'Take Exam';
        }
      case WorklistStatuses.ReviewComplete:
        try {
          const label = this.techpadSettings.worklistStatusesConfigs.find(status => status.status == WorklistStatuses.ReviewComplete).label;
          return label;
        } catch (error) {
          return 'Review Complete';
        }
      default:
        return '';
    }
  }

}
