import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NextLoginComponent } from './pages/next-login/next-login.component';
import { NextMembershipRoutingModule } from './next-membership-routing.module';
import { AuthProvider } from '../../../app/modules/core/contracts/auth.provider';
import { AuthService } from './services/auth.service';

@NgModule({
  declarations: [
    NextLoginComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule.forRoot(),
    NextMembershipRoutingModule
  ],
  providers: [
    {
      provide: AuthProvider,
      useClass: AuthService
    }
  ]
})
export class NextMembershipModule {
}
