import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { applicationConfiguration } from '../../../config';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class StudyService {

  constructor(private $http: HttpClient,
    private authService: AuthService) {
  }

  get(ssn, sDate) {
    const baseUrl = `${this.authService.currentMagViewUrl()}${applicationConfiguration.magViewApi}`;
    const url = `${baseUrl}/study?ssn=${ssn}&sdate=${sDate}`;
    return this.$http.get(url).toPromise();
  }

  getWorklist(ssn, sdate) {
    const url = this.authService.currentMagViewUrl() + '/api/worklist?ssn=' + ssn + '&sdate=' + sdate;

    return this.$http.get(url);
  }

  post(study): Promise<any> {
    const baseUrl = `${this.authService.currentMagViewUrl()}${applicationConfiguration.magViewApi}`;
    const url = `${baseUrl}/study`;
    return this.$http.post<any>(url, study).toPromise();
  }

  generatePinNumber(ssn, sDate): Promise<any> {
    const baseUrl = `${this.authService.currentMagViewUrl()}${applicationConfiguration.magViewApi}`;
    const url = `${baseUrl}/HistoryRequest/GeneratePin?ssn=${ssn}&sdate=${sDate}`;
    return this.$http.get<any>(url).toPromise();
  }

  skipSurveyAndStartExam(ssn, sdate, newState = null, internalSkip = null) {
    let url = this.authService.currentMagViewUrl() + '/api/worklist/skipSurveyAndStartExam?ssn=' + ssn + '&sdate=' + sdate;

    if (newState && newState != '') {
      url += '&state=' + newState;
    }

    if (internalSkip) {
      url += '&internalSkip=' + internalSkip;
    }

    return this.$http.get(url);
  }

  startSurvey(ssn, sdate, pinNumber, noUpdateStatus = false) {
    const worklistModel = {
      ssn,
      sdate
    } as any;

    if (!noUpdateStatus) {
      worklistModel.status = 'New';
    }

    if (pinNumber) {
      worklistModel.pin = pinNumber;
    }
    return this.$http.put<any>(this.authService.currentMagViewUrl() + '/api/worklist', worklistModel).toPromise();
  }

  getSurveyLog(surveyLogId) {
    return this.$http.get(this.authService.currentMagViewUrl() + '/api/survey/getSurveyLog?surveyLogId=' + surveyLogId);
  }

  reviewSurvey(surveyLogId) {
    return this.$http.post(this.authService.currentMagViewUrl() + '/api/survey/MakeSurveyReviewed?surveyLogId=' + surveyLogId, null);
  }

  checkPinNumber(pinNumber) {
    return this.$http.get(this.authService.currentMagViewUrl() + '/api/survey/CheckPin' + '?pin=' + pinNumber);
  }
  generateSurveySecureKey(pinNumber) {
    return this.$http.get(this.authService.currentMagViewUrl() + '/api/survey/GenerateSurveySecureKey' + '?pin=' + pinNumber);
  }
}
