import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { WorklistStatuses } from '../../../../modules/techpad-core/enums/worklist-stauses.enum';

@Component({
  selector: 'app-worklist-change-status',
  templateUrl: './worklist-change-status.component.html',
  styleUrls: ['./worklist-change-status.component.scss'],
})
export class WorklistChangeStatusComponent implements OnInit {

  @Input()
  id: number;

  @Input()
  item: any;

  availableStatuses: any[];

  constructor(private popoverController: PopoverController) {
  }

  ngOnInit() {
    const isLinked = this.item.study && this.item.study.isLinked;

    const showSurveyFinishedStatus = !isLinked && this.item.status !== WorklistStatuses.SurveyFinished;
    const showExamWaitingStatus = this.item.status !== WorklistStatuses.ExamWaiting;
    const showExamIncompleteStatus = this.item.status !== WorklistStatuses.ExamIncomplete;

    this.availableStatuses = [];

    if (showSurveyFinishedStatus) {
      this.availableStatuses.push(WorklistStatuses.SurveyFinished);
    }

    if (showExamWaitingStatus) {
      this.availableStatuses.push(WorklistStatuses.ExamWaiting);
    }

    if (showExamIncompleteStatus) {
      this.availableStatuses.push(WorklistStatuses.ExamIncomplete);
    }
  }

  changeStatus(status: string) {
    this.popoverController.dismiss({ item: this.item, status });
  }

}
