import { techpadVersion, version } from '../../package.json';

export const applicationConfiguration = {
  apiUrl: 'https://techpad-admin.magview.com/api/',
  magViewApi: '/magview.api',
  globalHttpTimeout: '200000',
  appVersionNumber: techpadVersion,
  expirationDate: '@EXPIRATION_DATE@',
  events: {
    portalNetworkAvailabilityChanged: 'portalConnectionAvailable-change',
    givingUpReconnecting: 'networkReconnectingGiveUp'
  },
  magviewUrl: null
};
