import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
    templateUrl: './worklist-change-list-message.component.html',
    styleUrls: ['./worklist-change-list-message.component.scss'],
})
export class WorklistChangeListMessageComponent implements OnInit {

    @Input()
    data: any;

    @Input()
    initialModel: any;

    isShowOtherMessage: boolean;

    constructor(private popoverController: PopoverController) {
    }

    ngOnInit() {
        this.data.newListMessage = (this.data.isRadiologist ? this.data.radListMessage : this.data.listMessage) || '';
        this.isShowOtherMessage = (this.data.isRadiologist && !!this.data.listMessage)
            || (!this.data.isRadiologist && !!this.data.radListMessage);
    }

    done() {
        this.popoverController.dismiss();
    }
}