import { Injectable } from '@angular/core';
import { WorklistResourcesProvider } from '../../../../app/modules/core/contracts/worklist-resources.provider';
import { Resource } from '../../../../app/modules/core/models/resource.model';

@Injectable()
export class NextWorklistResourcesProviderService implements WorklistResourcesProvider {
  getAvailableResources(worklistId): Promise<Resource[]> {
    return Promise.resolve([]);
  }

  isAllowedToChangeResources(worklist): Promise<boolean> {
    return Promise.resolve(true);
  }

  saveUpdateResource(worklistId, selectedResource): Promise<any> {
    return Promise.resolve(undefined);
  }
}
