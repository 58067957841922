import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from '../../../../modules/techpad-core/services/auth.service';
import * as _ from 'lodash';
import { LOOKUPS } from '../../../../modules/techpad-core/constants/lookups';
import { TechpadOptionsService } from '../../../../modules/techpad-core/services/techpad-options.service';
import { Subject, Subscription } from 'rxjs';
import { SignalrService } from '../../../../modules/core/services/signalr.service';
import { SubscriptionService } from 'src/app/modules/core/services/subscription.service';

@Component({
  selector: 'app-dashboard-window-modal',
  templateUrl: './dashboard-window-modal.component.html',
  styleUrls: ['./dashboard-window-modal.component.scss'],
})
export class DashboardWindowModalComponent implements OnInit, OnDestroy {

  @Input()
  item: any;

  statuses = LOOKUPS.statuses;
  isOnline: boolean;
  isRadiologist: boolean;
  listMessageReadOnly: boolean;

  changeDashboardItemStatusPopover: Subject<any>;
  changeListMessageAndResourcePopover: Subject<any>;
  isOnlineSubscription: Subscription;

  constructor(private modalController: ModalController,
              private techpadOptions: TechpadOptionsService,
              private authService: AuthService,
              private signalrService: SignalrService,
              private subscriptionService: SubscriptionService) {
    this.isOnlineSubscription =
      this.signalrService.isOnline
        .subscribe(isOnline => {
          this.isOnline = isOnline;
        });
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribe(this.isOnlineSubscription);
  }

  ngOnInit() {
    // TODO: Init isOnline
    this.isRadiologist = this.authService.isRadiologist();
    this.listMessageReadOnly = this.techpadOptions.options.listMessageReadOnly;
    this.item.newListMessage = this.isRadiologist ? this.item.radListMessage : this.item.listMessage;
    this.item.oldListMessage = _.cloneDeep(this.item.newListMessage);
  }

  onChangeDashboardItemStatusPopover($event) {
    this.changeDashboardItemStatusPopover.next({ event: $event, item: this.item });
  }

  onChangeListMessageAndResourcePopover($event) {
    this.changeListMessageAndResourcePopover.next({ event: $event, item: this.item });
  }

  onDashboardWindowSave() {
    this.modalController.dismiss({ item: this.item });
  }

}
