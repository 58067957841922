import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private MIN_SHOWING_TIME = 400;

  private loadingElement: HTMLIonLoadingElement;

  private counter: number = 0;

  private lastShowingTime: Date;

  private loadingElementDeletingPromise: Promise<void>;

  private isHidingInProgress: boolean;

  constructor(private loadingController: LoadingController) {
  }

  async showLoadingScreen(loadingOptions?: any) {
    if (this.loadingElementDeletingPromise) {
      await this.loadingElementDeletingPromise;
    }
    if (!this.loadingElement) {
      await this.createLoadingElement(loadingOptions);
    }
    this.counter += 1;
    this.updateMessageIfNeeded(loadingOptions);
    this.lastShowingTime = new Date();
    this.isHidingInProgress = false;
  }

  async hideLoadingScreen() {
    this.counter -= 1;
    if (this.counter === 0 && this.loadingElement) {
      this.triggerHidingLoadingScreen();
    }
  }

  private async createLoadingElement(loadingOptions?: any): Promise<void> {
    this.counter = 0;
    let options = loadingOptions;
    if (!options) {
      options = {
        message: 'Loading...',
      };
    }
    if (!options.message) {
      options.message = 'Loading...';
    }
    this.loadingElement = await this.loadingController.create(options);
    await this.loadingElement.present();
  }

  private triggerHidingLoadingScreen() {
    const loadingScreenWasShowingFor = new Date().getTime() - this.lastShowingTime.getTime();
    const remainingShowingTime = this.MIN_SHOWING_TIME - loadingScreenWasShowingFor;
    if (remainingShowingTime > 0) {
      setTimeout(() => {
        if (this.isHidingInProgress) {
          this.deleteLoadingElement();
        }
      }, remainingShowingTime);
      this.isHidingInProgress = true;
    } else {
      this.deleteLoadingElement();
    }
  }

  private async deleteLoadingElement() {
    this.loadingElementDeletingPromise = this.loadingElement.dismiss().then(() => {
      this.isHidingInProgress = false;
      this.loadingElement = null;
      this.loadingElementDeletingPromise = null;
    });
  }

  private updateMessageIfNeeded(loadingOptions?: any) {
    if (this.isHidingInProgress && loadingOptions && loadingOptions.message) {
      this.loadingElement.message = loadingOptions.message;
    }
  }
}
