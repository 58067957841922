import { Injectable } from '@angular/core';
import * as _ from 'lodash';

const cachePrefix = 'CACHED::::';
const cacheLengthLimit = 100;
export let cacheKeys: string[] = [];

export const setCacheKeys = (newCacheKeys) => {
  cacheKeys = newCacheKeys;
};

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  localStorage: any;
  cacheKeys: string[];

  constructor() {
    this.localStorage = window.localStorage;
  }

  set(key, value) {
    try {
      if (this.localStorage.length > cacheLengthLimit) {
        this.cleanUpStorage();
      }
      this.localStorage[key] = value;
    } catch (err) {
      console.log('cache limit exceeded, trying to cleanup');
      this.cleanUpStorage();
      try {
        this.localStorage[key] = value;
      } catch (err2) {
        console.log('cache limit still exceeded');
      }
    }
  }

  get(key, defaultValue = null) {
    return this.localStorage[key] || defaultValue;
  }

  delete(key) {
    delete this.localStorage[key];
  }

  setObject(key, value) {
    try {
      if (this.localStorage.length > cacheLengthLimit) {
        this.cleanUpStorage();
      }
      this.localStorage[key] = JSON.stringify(value);
    } catch (err) {
      console.log('cache limit exceeded, trying to cleanup');
      this.cleanUpStorage();
      try {
        this.localStorage[key] = JSON.stringify(value);
      } catch (err2) {
        console.log('cache limit still exceeded');
      }
    }
  }

  getObject(key) {
    let result = null;
    if (this.localStorage[key]) {
      result = JSON.parse(this.localStorage[key] || '{}');
    }
    return result;
  }

  cleanUp() {
    _.each(Object.keys(this.localStorage), (key) => {
      if (_.some(cacheKeys, (x) => _.includes(key, x))) {
        delete this.localStorage[key];
      }
    });
  }

  cleanUpStorage() {
    try {
      const keysToClean = [];
      for (const key in this.localStorage) {
        if (key.indexOf(cachePrefix) === 0) {
          let keyToClean = false;
          cacheKeys.forEach((cacheKey) => {
            if (key.indexOf(cacheKey) > 0) {
              keyToClean = true;
            }
          });

          if (keyToClean) {
            keysToClean.push(key);
          }
        }
      }

      if (!keysToClean.length) {
        return;
      }

      // Sort records by worklist id. Bigger id mean more recent record
      keysToClean.sort((left, right) => {
        const leftNum = parseInt(left.split('-').pop());
        const rightNum = parseInt(right.split('-').pop());
        return (leftNum > rightNum ? 1 : -1);
      });

      for (let i = 0; i < keysToClean.length / 2; i++) {
        delete this.localStorage[keysToClean[i]];
        console.log('Delete old cache key', keysToClean[i], i);
      }
    } catch (err) {
      console.log('Unable to clean local storage');
      console.log(err);
    }
  }
}
