import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { AuthService } from './auth.service';

const techpadOptionPrefixKey = 'TechpadOptions::';

@Injectable({
  providedIn: 'root'
})
export class TechpadOptionsService {

  options: any = null;
  currentUserName = '';

  constructor(private authService: AuthService,
    private localStorage: LocalStorageService,
    @Inject(DOCUMENT) private document: Document) {
  }

  init() {
    this.getOptions();
    this.checkAndApplyDarkMod();
  }

  getOptions() {
    if (this.options === null || this.currentUserName !== this.authService.getUserName()) {
      this.currentUserName = this.authService.getUserName();
      this.options = this.localStorage.getObject(techpadOptionPrefixKey + this.currentUserName) || {};
    }
    return this.options;
  }

  saveOptions(newOptions) {
    this.localStorage.setObject(techpadOptionPrefixKey + this.currentUserName, newOptions);
    this.options = newOptions;
  }

  checkAndApplyDarkMod() {
    if (this.options.darkMode && !this.document.body.classList.contains('dark-mode')) {
      this.document.body.classList.add('dark-mode');
    }
  }
}
