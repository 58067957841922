import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, Input, OnDestroy, Type, ViewChild } from '@angular/core';
import { GenericContentDirective } from '../../directives/generic-header-content.directive';
import { ComponentContent } from '../../models/component-content.model';

@Component({
  selector: 'app-worklist-item',
  templateUrl: './worklist-item.component.html',
  styleUrls: ['./worklist-item.component.scss'],
})
export class WorklistItemComponent implements AfterViewInit, OnDestroy {

  componentRef: ComponentRef<ComponentContent>;

  @Input()
  worklistItemType: Type<any>;

  @Input()
  worklistItem: any;

  @ViewChild(GenericContentDirective, { static: false })
  appGenericContentDirective: GenericContentDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {

  }

  ngAfterViewInit(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.worklistItemType);
    const viewContainerRef = this.appGenericContentDirective.viewContainerRef;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent<ComponentContent>(componentFactory);
    this.componentRef.instance.data = this.worklistItem;
    this.componentRef.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.componentRef.destroy();
  }
}
