import { Injectable } from "@angular/core";
import {
  HttpEvent, HttpInterceptor, HttpHandler,
  HttpRequest, HttpErrorResponse
} from "@angular/common/http";
import { throwError, Observable } from "rxjs";
import { catchError, retry } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ModalService } from '../../../../app/modules/core/services/modal.service';
import { LoadingService } from '../../../../app/modules/core/services/loading.service';
import { Router } from '@angular/router';

@Injectable()
export class NextErrorInterceptor implements HttpInterceptor {

  timesToRepeatFailedRequest = 0;

  constructor(private authService: AuthService,
    private loadingService: LoadingService,
    private router: Router,
    private modalDialogService: ModalService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retry(this.timesToRepeatFailedRequest),
      catchError((e: HttpErrorResponse) => {
        if (e.status !== 401) {
          this.loadingService.hideLoadingScreen();
          this.modalDialogService.showErrorAlert(e.error && e.error.errorMessage || e.message);
        }

        if (e.status !== 500 && e.status !== 400) {
          this.router.navigate(['/', 'login'])
        }

        return throwError(e);
      })
    );
  }
}
