import { Injectable } from '@angular/core';
import * as signal from 'signal-js';

@Injectable({
  providedIn: 'root'
})
export class PubsubService {
  static singleInstance;

  private pubsubChannel = new signal.default();

  static getInstance() {
    return (PubsubService.singleInstance || (PubsubService.singleInstance = new PubsubService()));
  }

  on(eventName, callback) {
    this.pubsubChannel.on(eventName, callback);
  }

  off(eventName) {
    this.pubsubChannel.off(eventName);
  }

  dispatch(eventName, ...params) {
    this.pubsubChannel.trigger(eventName, ...params);
  }
}

export const pubsubService = PubsubService.getInstance();
