import { EventEmitter, Injectable } from '@angular/core';
import { RealtimeConnectionService } from './realtime.service';
import { AuthService } from '../../../../app-next/modules/membership/services/auth.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorklistRealtimeService {

  worklistCreated = new EventEmitter<any>();
  worklistUpdated = new EventEmitter<any>();
  worklistDeleted = new EventEmitter<any>();

  realtimeClientMethods = {
    created: (worklistItemSerialized) => {
      const item = JSON.parse(worklistItemSerialized);
      this.worklistCreated.next(item);
    },
    updated: (worklistItemSerialized) => {
      const item = JSON.parse(worklistItemSerialized);
      this.worklistUpdated.next(item);
    },
    deleted: (worklistItemSerialized) => {
      const item = JSON.parse(worklistItemSerialized);
      this.worklistDeleted.next(item);
    }
  }

  constructor(private realtimeConnection: RealtimeConnectionService) {
    this.realtimeConnection.onConnected.subscribe(() => {
      Object.keys(this.realtimeClientMethods)
        .forEach(key => {
          this.realtimeConnection.on(key, this.realtimeClientMethods[key]);
        });
    });
  }
}
