import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { ModalService } from '../../core/services/modal.service';
import { CONSTANT_STRINGS } from '../constants/constants';
import { AuthService } from './auth.service';
import { TechpadConfigService } from './techpad-config.service';
import { WorklistService } from './worklist.service';

@Injectable({
    providedIn: 'root'
})
export class WorklistOwnershipService {

    constructor(private Worklist: WorklistService,
        private authService: AuthService,
        private techpadConfig: TechpadConfigService,
        private modalService: ModalService,
        private router: Router,
        private modalController: ModalController) {
    }

    public async isOwnerOrConfirmedToTakeOwnership(worklist) {
        // TODO: implement reject
        return new Promise<boolean>(async (resolve, reject) => {
            const currentUserEmail = this.authService.getUserName() || '';
            const isOwnershipTakingRequired = worklist.technologist
                && worklist.technologist.email.toLowerCase() !== currentUserEmail.toLowerCase();
            if (isOwnershipTakingRequired) {
                const isConfirmed = await this.confirmOwnershipTaken(worklist, null, async () => {
                    resolve(true);
                });
                if (!isConfirmed) {
                    resolve(false);
                }
            } else {
                resolve(true);
            }
        });
    }

    private confirmOwnershipTaken(item, modal, callback) {

        console.log('Owner ship taken required: ', item, item.technologist, this.authService.getUserName());

        /*
        const errorObject = {} as any;
        errorObject.data = {
          Context: 'Ownership switching requested',
          CurrentWorklistTech: this.data.technologist
        };
        errorObject.timestamp = new Date();
        errorObject.hospital = localStorage.getObject('hospital');
        errorObject.user = this.getUser();
        errorObject.device = localStorage.getObject('MagViewDeviceInfo');
    
        this.$http.post(this.authService.currentMagViewUrl() + '/api/logging/logTechpadErrors', [errorObject]).subscribe(() => {
          // all errors send successfully
        }, () => {
        });
        */

        const currentOwner = `${item.technologist.firstName} ${item.technologist.lastName}`;
        const confirmationQuestion = `This exam is owned by technologist ${currentOwner}. Are you sure to take over this exam ?`;
        return this.modalService.showConfirmation(() => {
            const isTakeOwnership = true;
            this.assignAndOpen(item, modal, callback, isTakeOwnership);
        }, confirmationQuestion);
    }

    async assignAndOpen(item, modal, callback = null, isTakeOwnership = null) {
        let assignResp: any;
        try {
            assignResp = await this.Worklist.assignToMe(item.id, isTakeOwnership);
            if (assignResp.success) {
                if (assignResp.newOwner) {
                    item.technologist = assignResp.newOwner;
                }
                await this.handleAssigningSucess(item, callback);
            } else {
                await this.handleAssigningError(assignResp, item, modal, callback);
            }
        }
        catch (error) {
            this.modalService.showErrorMessage(error.data, error.status);
        }
    }

    private async handleAssigningSucess(item, callback) {
        if (callback) {
            callback();
        }
        else {
            const isAutoStart = this.techpadConfig.settings.hideStartAndEndButtons;
            if (isAutoStart) {
                await this.Worklist.updateStatus(item.id, CONSTANT_STRINGS.worklistStatuses.ExamInProgress.status);
            }
            this.router.navigate([`/worklist-detail/${item.id}`]);
            this.modalController.dismiss({ isRedirect: true });
        }
    }

    private async handleAssigningError(assignResp, item, modal, callback) {
        if (assignResp.isOwnershipTakingRequired) {
            this.confirmOwnershipTaken(item, modal, callback);
        } else if (!!assignResp.currentUser) {
            // another user is modifying at the moment
            this.modalService.showAdvancedConfirmation(
                'Cannot perform exam',
                this.getCurrentlyModifyingMessage(assignResp.currentUser),
                [{
                    text: 'Unlock this exam',
                    cssClass: 'button-assertive',
                    handler: () => {
                        this.unlockExamPrompt(item, modal, callback);
                    }
                }, {
                    text: 'View Only',
                    cssClass: 'button-positive',
                    handler: () => {
                        if (!callback) {
                            this.openAsViewOnly(item.id, modal);
                        }
                    }
                }]);
        } else if (assignResp.onHold) {
            this.modalService.showAlert('This worklist item is currently on hold.');
        } else {
            this.modalService.showAlert('This study record not found in database');
        }
    }

    public getCurrentlyModifyingMessage(currentUser) {
        return `User '${currentUser.userName}' on workstation '${currentUser.clientWorkstation}' is currently modifying this study. ` +
            `You will be able to review current study information, but not change or save the study. ` +
            `This event was started on ${moment(currentUser.lockedFrom).calendar()}`
    }

    private unlockExamPrompt(item, modal, callback) {
        this.modalService.showAdvancedConfirmation(
            'Unlock Exam Confirmation',
            'Are you sure to remove lock from this study and assign it to you ?', [{
                text: 'No',
                handler: () => {

                }
            }, {
                text: 'Yes, Unlock It',
                cssClass: 'button-assertive',
                handler: async () => {
                    await this.Worklist.unlockExam(item.id);
                    const isTakeOwnership = true;
                    this.assignAndOpen(item, modal, callback, isTakeOwnership);
                }
            }]);
    }

    openAsViewOnly(id, modal) {
        this.router.navigate([`/worklist-detail/${id}`, { viewOnly: true }]);
        this.modalController.dismiss({ isRedirect: true });
    }
}