import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComponentContent } from '../../models/component-content.model';
import { SignalrService } from '../../services/signalr.service';

@Component({
  selector: 'app-generic-header-content',
  templateUrl: './generic-header-content.component.html',
  styleUrls: ['./generic-header-content.component.scss'],
})
export class GenericHeaderContentComponent implements ComponentContent, OnInit, OnDestroy {

  @Input()
  data: any;

  private subscription: Subscription = new Subscription();

  isOnline: boolean;

  constructor(private signalrService: SignalrService) {
  }

  ngOnInit() {
    this.subscription.add(this.signalrService.isOnline.subscribe(isOnline => {
      this.isOnline = isOnline;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
