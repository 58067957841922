import { Injectable } from '@angular/core';
import { AuthProvider } from '../contracts/auth.provider';
import { CachedStorageService } from './cached-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DataRetrieverService {
  constructor(
    private authProvider: AuthProvider,
    private cachedStorageService: CachedStorageService,
  ) { }

  cachedProxy(originalKey: string, cacheTime: number, retrieveFn: () => Promise<any>): any {
    const key = `${this.authProvider.getUserName()}-${originalKey}`;

    return new Promise((resolve, reject) => {
      const value = this.cachedStorageService.retrieve(key, false);
      if (value) {
        resolve(value);
      } else {
        retrieveFn().then(
          (resp) => {
            if (cacheTime > 0) {
              this.cachedStorageService.store(key, resp, cacheTime);
            }
            resolve(resp);
          },
          (err) => {
            if (
              err.status === 500
              || err.status === 404
              || err.status === 401
            ) {
              reject(err);
              return;
            }

            const val = this.cachedStorageService.retrieve(key, true);
            if (val) {
              // indicate that we don't have up to date version of requested data
              val._offlineVersion = true;
              resolve(val);
            } else {
              reject(err);
            }
          },
        );
      }
    });
  }
}
