import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { WorklistStatusProviderService } from 'src/app/modules/techpad-core/services/worklist-status-provider.service';
import { LoadingService } from '../../../../modules/core/services/loading.service';
import { ModalService } from '../../../../modules/core/services/modal.service';
import { SignalrService } from '../../../../modules/core/services/signalr.service';
import { CONSTANT_STRINGS } from '../../../../modules/techpad-core/constants/constants';
import { LOOKUPS } from '../../../../modules/techpad-core/constants/lookups';
import { AuthService } from '../../../../modules/techpad-core/services/auth.service';
import { StudyService } from '../../../../modules/techpad-core/services/study.service';
import { WorklistOwnershipService } from '../../../../modules/techpad-core/services/worklist-ownership.service';
import { WorklistService } from '../../../../modules/techpad-core/services/worklist.service';

@Component({
  templateUrl: './worklist-exam-modal.component.html',
  styleUrls: ['./worklist-exam-modal.component.scss'],
})
export class WorklistExamModalComponent implements OnInit, OnDestroy {

  @Input()
  data: any;

  @Input()
  isLocked: any;

  private subscription: Subscription = new Subscription();

  isShowGeneratePinNumber: boolean;
  canChangeStatus: boolean;
  isExamMine: boolean;

  statuses = LOOKUPS.statuses;
  isOnline: boolean;
  modaltoastMsg: string;

  constructor(private authService: AuthService,
    private modalService: ModalService,
    private Worklist: WorklistService,
    private Study: StudyService,
    private modalController: ModalController,
    private signalrService: SignalrService,
    private loadingService: LoadingService,
    private worklistOwnershipService: WorklistOwnershipService,
    private worklistStatusProviderService: WorklistStatusProviderService,
    private toastController: ToastController) {
  }

  ngOnInit() {

    this.subscription.add(this.signalrService.isOnline.subscribe(isOnline => {
      this.isOnline = isOnline;
    }));

    this.isShowGeneratePinNumber = (
      this.data.Status == this.statuses[0]
      || this.data.status == this.statuses[1]
    ) && this.data.arrivalStatus !== CONSTANT_STRINGS.arrivalStatuses.PatientArrived
      && !this.isLocked;

    this.canChangeStatus = this.getCanChangeStatus();

    const currentUserEmail = this.authService.getUserName() || '';
    this.isExamMine = this.data.technologist && this.data.technologist.email.toLowerCase() === currentUserEmail.toLowerCase();
  }

  private getCanChangeStatus() {

    if (!this.data.study || !this.data.isLinkedStudy) {
      return true;
    }

    let notAllowedStatuses = [
      CONSTANT_STRINGS.worklistStatuses.New.status, CONSTANT_STRINGS.worklistStatuses.SurveyStarted.status
    ];

    return notAllowedStatuses.indexOf(this.data.status) < 0;
  }

  async generatePinNumber() {

    if (await this.worklistOwnershipService.isOwnerOrConfirmedToTakeOwnership(this.data)) {
      this.generatePinAndStartSurvey();
    }

    /*const currentUserEmail = this.authService.getUserName() || '';
    let isOwnershipTakingRequired = this.data.technologist && this.data.technologist.email.toLowerCase() !== currentUserEmail.toLowerCase();
    if (isOwnershipTakingRequired) {
      this.worklistOwnershipService.confirmOwnershipTaken(this.data, null, async () => {
        this.generatePinAndStartSurvey();
      });
    } else {
      this.generatePinAndStartSurvey();
    }*/
  }

  async generatePinAndStartSurvey() {

    await this.loadingService.showLoadingScreen({ message: 'Loading...' });

    const pin = await this.Study.generatePinNumber(this.data.ssn, this.data.sDate);
    await this.Study.startSurvey(this.data.ssn, this.data.sDate, pin, true);
    this.data.pinNumber = pin;

    this.preparePinNumberForOpeningStudy();

    this.loadingService.hideLoadingScreen();
  }

  async openItem(viewOnly, $event, modal) {

    if (this.data.pinNumber) {
      this.preparePinNumberForOpeningStudy();
    }

    if (!viewOnly) {
      this.worklistOwnershipService.assignAndOpen(this.data, modal);
    } else {
      $event.preventDefault();
      this.worklistOwnershipService.openAsViewOnly(this.data.id, modal);
    }
  }

  private preparePinNumberForOpeningStudy() {
    /*const ssn = item.Ssn;
    const sDate = item.SDate;
    let existingPinObject = _.find($rootScope.pinNumbers, {
      'Ssn': ssn,
      'SDate': sDate
    });
    if (existingPinObject != null) {
      $rootScope.pinNumbers.pop(_.find($rootScope.pinNumbers, {
        'Ssn': ssn,
        'SDate': sDate
      }));
    }
    $rootScope.pinNumbers.push({
      'Ssn': ssn,
      'SDate': sDate,
      'PinNumber': item.PinNumber
    });*/
  }

  unassignWorklist() {
    this.modalService.showConfirmation(() => {
      this.Worklist.unassignFromMe(this.data.id).subscribe((resp) => {
        this.modalController.dismiss({ shouldRefresh: true });
      }, (error) => {
        console.error(error);
      });
    }, 'Are you sure you want to un-assign this patient from yourself?');
  }

  async presentToast(modaltoastMsg) {
    const toast = await this.toastController.create({
      message: modaltoastMsg,
      duration: 2000
    });
    toast.present();
  }

  async changeStatusPopover($event) {
    if (!this.isLocked) {
      await this.worklistStatusProviderService.changeStatusPopover(this.data, $event);
      try {
        this.modaltoastMsg = 'Status have been changed.';
        this.presentToast(this.modaltoastMsg);
      } catch (error) {
        this.modaltoastMsg = 'Error! Status have not been changed.';
        this.presentToast(this.modaltoastMsg);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
