import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {filter} from 'rxjs/operators';


interface EventMessage {
  name: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class EventsMgrService {

  private eventSubject$ = new Subject<EventMessage>();
  private eventObservable$ = this.eventSubject$.asObservable();

  constructor() {
  }

  on(name, cb) {
    const subscription = this.eventObservable$.pipe(filter(_ => _.name === name))
      .subscribe(value => {
        cb(value.data);
      });

    return {subscription, unsubscribe: subscription.unsubscribe};
  }

  send(name, obj?) {
    this.eventSubject$.next({name, data: obj});
  }
}
