import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { IonInputAutocompleteModalComponent } from '../ion-input-autocomplete-modal/ion-input-autocomplete-modal.component';
import { IonSpinnerNameEnum } from '../../enums/ion-spinner.enum';
import { LoadItemsModel } from '../../models/load-items.model';
import { LoadMoreEventModel } from '../../models/load-more-event.model';
import { LoadItemsCallbackModel } from '../../models/load-items-callback.model';
import { LoadMoreEventCallbackModel } from '../../models/load-more-event-callback.model';

@Component({
  selector: 'app-ion-input-autocomplete',
  templateUrl: './ion-input-autocomplete.component.html',
  styleUrls: ['./ion-input-autocomplete.component.scss'],
})
export class IonInputAutocompleteComponent implements OnInit {
  @Input() cancelLabel = 'Done';

  @Input() items: any[];

  @Input() loadingIcon: IonSpinnerNameEnum | undefined;

  @Input() placeholder = 'Click to enter a value...';

  @Input() selectItemsLabel = 'Select an item...';

  @Input() selectedItemLabel = 'Selected item:';

  @Input() itemValueKey: string;

  @Input() itemViewValueKey: string;

  @Input() showLoadMore: boolean;

  @Input() selectedItem: any;

  @Output() selectedItemChange = new EventEmitter<any>();

  @Input() displayValue: any;

  @Output() modalClose = new EventEmitter<any>();

  @Output() loadItems = new EventEmitter<any>();

  loadMoreEvent = new EventEmitter<any>();

  loadItemsEvent = new EventEmitter<any>();

  itemsSubject = new Subject();

  constructor(private modalService: ModalService) {
  }

  ngOnInit() {
    this.loadMoreEvent.subscribe(($event: LoadMoreEventModel) => {
      const loadItemsModel = {
        pageIndex: $event.pageIndex,
        searchQuery: $event.searchQuery,
        callback: (loadItemsCallback) => {
          this.items = loadItemsCallback.loadedItems;
          this.itemsSubject.next(this.items);
          const loadMoreEventCallback = {
            showLoadButton: loadItemsCallback.showLoadMore,
            isSuccess: true,
          } as LoadMoreEventCallbackModel;
          $event.callback(loadMoreEventCallback);
        },
        isOverride: false,
      } as LoadItemsModel;
      this.loadItems.emit(loadItemsModel);
    });

    this.loadItemsEvent.subscribe(($event: LoadItemsModel) => {
      const loadItemsModel = {
        pageIndex: $event.pageIndex,
        searchQuery: $event.searchQuery,
        callback: (loadItemsCallback) => {
          this.items = loadItemsCallback.loadedItems;
          this.itemsSubject.next(this.items);
          const loadItemsEventCallback = {

          } as LoadItemsCallbackModel;
          $event.callback(loadItemsEventCallback);
        },
        isOverride: true,
      } as LoadItemsModel;
      this.loadItems.emit(loadItemsModel);
    });
  }

  async onInputClicked($event): Promise<any> {
    const modal = await this.modalService.showDialogWindow({
      component: IonInputAutocompleteModalComponent,
      componentProps: {
        cancelLabel: this.cancelLabel,
        itemsBehaviorSubject: this.itemsSubject,
        loadMore: this.loadMoreEvent,
        loadItems: this.loadItemsEvent,
        loadingIcon: this.loadingIcon,
        selectedItemLabel: this.selectedItemLabel,
        selectItemsLabel: this.selectItemsLabel,
        itemValueKey: this.itemValueKey,
        itemViewValueKey: this.itemViewValueKey,
        showLoadMore: this.showLoadMore,
        selectedItem: this.selectedItem,
      } as IonInputAutocompleteModalComponent,
      cssClass: 'ion-input-autocomplete-modal',
      backdropDismiss: false,
    });

    const loadItemsModel = {
      pageIndex: 0,
      searchQuery: '',
      callback: (loadItemsCallback) => {
        this.items = loadItemsCallback.loadedItems;
        this.itemsSubject.next(this.items);
      },
    } as LoadItemsModel;
    this.loadItems.emit(loadItemsModel);

    modal.onWillDismiss().then((result) => {
      this.selectedItem = result.data;
      this.selectedItemChange.emit(this.selectedItem);
      this.modalClose.emit();
      // if (!result.data.cancelled && !_.isEqual(this.vm.implantData, result.data.implantData)) {
      //   this.vm.implantData = result.data.implantData;
      //   this.implantString = this.getImplantString();
      // }
    });
  }
}
