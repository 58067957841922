import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { applicationConfiguration } from 'src/app/config';
import { LookupModel } from '../../core/models/lookup.model';
import { ModalService } from '../../core/services/modal.service';
import { SignalrService } from '../../core/services/signalr.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { WorklistStatuses } from '../enums/worklist-stauses.enum';
import { AuthService } from './auth.service';
import { ServiceTimeService } from './service-time.service';
import { TechpadConfigService } from './techpad-config.service';
import { ViewTypeModel } from '../models/view-type.model';
import { PatientDtoModel } from '../models/patient-dto.model';
import { WorklistItemModel } from '../models/worklist-item.model';
import { CheckStudyLockResponseModel } from '../models/check-study-lock-response.model';
import { WorklistAllLookupModel } from '../models/worklist-all-lookup.model';
import { UltrasoundScanOptionsModel } from '../models/ultrasound-scan-options.model';
import { UltrasoundScanRecordsModel } from '../models/ultrasound-scan-records.model';
import { SaveUltrasoundScanResultsModel } from '../models/save-ultrasound-scan-results.model';
import { DeleteUltrasoundTechniqueModel } from '../models/delete-ultrasound-technique.model';

@Injectable({
  providedIn: 'root',
})
export class WorklistService implements OnDestroy {
  magviewHistoryApiUrl = 'api/magviewhistory';

  ultraSoundApiUrl = 'api/ultrasoundScanTechnique';

  itemCreated = null;

  itemUpdated = null;

  itemRemoved = null;

  itemUnlockStatusChanged = null;

  itemRemovedStatusChanged = null;

  isOnlineSubscription: Subscription;

  isOnline: boolean;

  onRefresh: EventEmitter<any> = new EventEmitter<any>();

  onOpenPatientDetails: EventEmitter<any> = new EventEmitter<any>();

  constructor(private $http: HttpClient,
    private authService: AuthService,
    private modalService: ModalService,
    private techpadConfig: TechpadConfigService,
    private serverTimeService: ServiceTimeService,
    private signalrService: SignalrService,
    private subscriptionService: SubscriptionService) {
    this.isOnlineSubscription = this.signalrService.isOnline
      .subscribe(isOnline => {
        this.isOnline = isOnline;
      });

    // signalrService.worklist.on('itemCreated', this.onWorklistItemCreatedEventHandler);
    // signalrService.worklist.on('itemUpdated', this.onWorklistItemUpdatedEventHandler);
    // signalrService.worklist.on('worklistLockStatusChanged', this.onWorklistItemLockStatusChangedEventHandler);
    // signalrService.worklist.on('worklistRemovedStatusChanged', this.onWorklistItemRemovedStatusChangedEventHandler);
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribe(this.isOnlineSubscription);
  }

  onWorklistItemCreatedEventHandler = (item) => {
    if (this.itemCreated) {
      this.itemCreated(item);
    }
  };

  onWorklistItemUpdatedEventHandler = (item) => {
    if (this.itemUpdated) {
      this.itemUpdated(item);
    }
  };

  onWorklistItemLockStatusChangedEventHandler = (item) => {
    if (this.itemUnlockStatusChanged) {
      this.itemUnlockStatusChanged(item);
    }
  };

  onWorklistItemRemovedStatusChangedEventHandler = (item) => {
    if (this.itemRemovedStatusChanged) {
      this.itemRemovedStatusChanged(item);
    }
  };

  getAll(filterRequest, isSyncWithMagview) {
    const data = {
      ...filterRequest,
      isSyncWithMagview,
    };

    const url = `${this.authService.currentMagViewUrl()}/api/worklist/getAll`;
    return this.$http.post(url, data).toPromise();
  }

  getWorklistItem(id): Promise<WorklistItemModel> {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/${id}`;
    return this.$http.get<WorklistItemModel>(url).toPromise();
  }

  updatePatientInfo(patient: any) {
    const url = `${this.authService.currentMagViewUrl() + applicationConfiguration.magViewApi}/patient/create`;
    return this.$http.post(url, patient).toPromise();
  }

  async getPatientComments(id) {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/${id}/comments`;
    return this.$http.get(url).toPromise();
  }

  getPatientInfoBySsn(ssn): Promise<PatientDtoModel> {
    const url = `${this.authService.currentMagViewUrl() + applicationConfiguration.magViewApi}/Patient/GetPatientsDetails?ssn=${ssn}`;
    return this.$http.get<PatientDtoModel>(url).toPromise();
  }

  getAllLookups(): Promise<WorklistAllLookupModel> {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/lookup/all`;
    return this.$http.get<WorklistAllLookupModel>(url).toPromise();
  }

  getVisitReasons(): Observable<ViewTypeModel[]> {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/lookup/VisitReasons`;
    return this.$http.get<ViewTypeModel[]>(url);
  }

  getTecknologists() {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/lookup/Tecknologists`;
    return this.$http.get(url);
  }

  getProblems() {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/lookup/Problems`;
    return this.$http.get(url);
  }

  getProcedures() {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/lookup/Procedures`;
    return this.$http.get<any[]>(url).toPromise();
  }

  getViews() {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/lookup/Views`;
    return this.$http.get(url);
  }

  getUdfs() {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/Udfs`;
    return this.$http.get(url);
  }

  getCitiesAndZipCodesByStateCode(code: string) {
    return this.$http.get(`${this.authService.currentMagViewUrl()}/api/Zip/GetCitiesAndZipCodesByStateCode?stateCode=${code}`).toPromise();
  }

  getRaces(): Promise<LookupModel[]> {
    return this.$http.get<LookupModel[]>(`${this.authService.currentMagViewUrl() + applicationConfiguration.magViewApi}/lookups/races`).toPromise();
  }

  getLanguages(): Promise<LookupModel[]> {
    return this.$http.get<LookupModel[]>(`${this.authService.currentMagViewUrl() + applicationConfiguration.magViewApi}/lookups/languages`).toPromise();
  }

  getExamTypes() {
    return this.$http.get(`${this.authService.currentMagViewUrl()}/api/worklist/lookup/ExamTypes`).toPromise();
  }

  getExamTypesInGroups(examTypeGroups) {
    return this.$http.post(`${this.authService.currentMagViewUrl()}/api/worklist/lookup/ExamTypesInGroups`, { examTypeGroups });
  }

  getLocations(): Promise<LookupModel[]> {
    return this.$http.get<LookupModel[]>(`${this.authService.currentMagViewUrl()}/api/worklist/lookup/Locations`).toPromise();
  }

  getAllLocations(includeInternal: boolean = true): Observable<LookupModel[]> {
    return this.$http.get<LookupModel[]>(`${this.authService.currentMagViewUrl()}/api/worklist/lookup/Locations?includeInternal=${includeInternal}`);
  }

  readyForExam(surveyQueueId) {
    const url = `${this.authService.currentMagViewUrl()}/api/HistoryPortal/ReadyForExam`;
    const body = { SurveyQueueId: surveyQueueId };
    return this.$http.post(url, body);
  }

  getUltraSoundScanOptions(): Promise<UltrasoundScanOptionsModel> {
    const url = `${this.authService.currentMagViewUrl()}/${this.ultraSoundApiUrl}/options`;
    const promise = this.$http.get<UltrasoundScanOptionsModel>(url).toPromise();
    return promise;
  }

  async getUltraSoundScanResults(ssn, sDate): Promise<UltrasoundScanRecordsModel> {
    const url = `${this.authService.currentMagViewUrl()}/${this.ultraSoundApiUrl}/scanResults?ssn=${ssn}&sDate=${sDate}`;
    const promise = this.$http.get<UltrasoundScanRecordsModel>(url).toPromise();
    return promise;
  }

  saveUltraSoundScanResults(ssn, sDate, data): Promise<SaveUltrasoundScanResultsModel> {
    const url = `${this.authService.currentMagViewUrl()}/${this.ultraSoundApiUrl}/scanResults?ssn=${ssn}&sDate=${sDate}`;
    const promise = this.$http.post<SaveUltrasoundScanResultsModel>(url, data).toPromise();
    return promise;
  }

  async deleteUltrasoundTechnique(id): Promise<DeleteUltrasoundTechniqueModel> {
    const url = `${this.authService.currentMagViewUrl()}/${this.ultraSoundApiUrl}/delete/${id}`;
    const promise = this.$http.delete<DeleteUltrasoundTechniqueModel>(url).toPromise();
    return promise;
  }

  async getHistoryConfigAndData(ssn, sDate, rightHistoryTypes, isIncudeConfig, isIncudeImplants) {
    let url = `${this.authService.currentMagViewUrl()}/${this.magviewHistoryApiUrl}/HistoryConfigAndData?ssn=${ssn}&sDate=${sDate}`;
    if (isIncudeConfig !== undefined) {
      url += `&isIncudeConfig=${isIncudeConfig}`;
    }
    if (isIncudeImplants !== undefined) {
      url += `&isIncudeImplants=${isIncudeImplants}`;
    }
    for (const type of (rightHistoryTypes || [])) {
      url += `&types[]=${type}`;
    }
    return this.$http.get(url).toPromise();
  }

  getHistoryConfig(ssn) {
    return this.$http.get(`${this.authService.currentMagViewUrl()}/${this.magviewHistoryApiUrl}/config?ssn=${ssn}`);
  }

  getImplantData(ssn, sDate) {
    return this.$http.get(`${this.authService.currentMagViewUrl()}/${this.magviewHistoryApiUrl}/implantData?ssn=${ssn}&sDate=${sDate}`);
  }

  deleteRightHistoryRecord(model) {
    return this.$http.post(`${this.authService.currentMagViewUrl()}/${this.magviewHistoryApiUrl}/DeleteRightHistoryData`, model);
  }

  getLeftHistoryData(ssn, sDate) {
    return this.$http.get(`${this.authService.currentMagViewUrl()}/${this.magviewHistoryApiUrl}/leftHistoryData?ssn=${ssn}&sDate=${sDate}`);
  }

  getRightHistoryData(ssn, sDate, types) {
    let url = `${this.authService.currentMagViewUrl()}/${this.magviewHistoryApiUrl}/rightHistoryData?ssn=${ssn}&sDate=${sDate}`;
    types.forEach((x) => {
      url += `&types=${x}`;
    });
    return this.$http.get(url);
  }

  saveChanges(model) {
    return this.$http.put(`${this.authService.currentMagViewUrl()}/api/worklist/SaveItem`, model).toPromise();
  }

  updateStatus(id, status) {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/${id}/UpdateStatus?status=${status}`;
    const body = null;
    return this.$http.post(url, body).toPromise();
  }

  updateListMessage(id, listMessage) {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/${id}/updateListMessage?listMessage=${listMessage}`;
    return this.$http.post(url, null).toPromise();
  }

  updateResource(id, resource) {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/${id}/updateResource?resource=${encodeURIComponent(resource)}`;
    return this.$http.post<any>(url, null).toPromise();
  }

  endExam(id) {
    return this.$http.post(`${this.authService.currentMagViewUrl()}/api/worklist/${id}/endExam`, null);
  }

  remove(id) {
    return this.$http.delete(`${this.authService.currentMagViewUrl()}/api/worklist/${id}`);
  }

  processCreatedRIS(id) {
    return this.$http.post(`${this.authService.currentMagViewUrl()}/api/worklist/${id}/processCreatedRIS`, null);
  }

  isAssignedToMe(id) {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/${id}/isAssignedToMe`;
    return this.$http.get(url);
  }

  assignToMe(id, isTakeOwnership = false): Promise<any> {
    let url = `${this.authService.currentMagViewUrl()}/api/worklist/${id}/assignToMe`;
    if (isTakeOwnership) {
      url += '?forceAssign=true';
    }
    return this.$http.post<any>(url, null).toPromise();
  }

  checkStudyLock(id: number): Promise<CheckStudyLockResponseModel> {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/${id}/checkStudyLock`;
    return this.$http.post<CheckStudyLockResponseModel>(url, null).toPromise();
  }

  getChangeStatusesConfigs(worklistId) {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/getChangeStatusesConfigs?worklistId=${worklistId}`;
    return this.$http.get(url);
  }

  ownWorklist(worklistId) {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/${worklistId}/ownWorklist`;
    return this.$http.put(url, null);
  }

  getDisplayStatusesConfigs() {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/getDisplayStatusesConfigs`;
    return this.$http.get(url).toPromise();
  }

  checkStudyLockBySsnAndSdate(ssn, sdate): Promise<any> {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/checkStudyLock?ssn=${ssn}&sdate=${sdate}`;
    return this.$http.get<any>(url).toPromise();
  }

  checkIfWorklistExists(ssn, sdate): Promise<any> {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/checkIfWorklistExists?ssn=${ssn}&sdate=${sdate}`;
    return this.$http.get<any>(url).toPromise();
  }

  applyListMessage(worklistId, label) {
    const data = {
      worklistId,
      value: label,
    };
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/applyListMessage`;
    return this.$http.put(url, data).toPromise();
  }

  toggleTag(worklistId, tag) {
    const data = {
      worklistId,
      value: tag,
    };
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/toggleTag`;
    return this.$http.put(url, data).toPromise();
  }

  applyAdditionalInfo(worklistId, additionalInfo) {
    const data = {
      worklistId,
      value: additionalInfo,
    };
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/applyAdditionalInfo`;
    return this.$http.put(url, data);
  }

  unassignFromMe(id) {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/${id}/unassign`;
    return this.$http.post(url, null);
  }

  unlockExam(id) {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/${id}/unlockExam`;
    return this.$http.post(url, null).toPromise();
  }

  getSameDayStudies(id): Observable<any[]> {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/${id}/getSameDayStudies`;
    return this.$http.get<any[]>(url);
  }

  saveLinkedStudies(id, studies) {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/${id}/saveLinkedStudies`;
    return this.$http.post(url, studies);
  }

  historyChangedInMagview(id) {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/historyChangedInMagview/${id}`;
    return this.$http.get(url);
  }

  async recalculateRisks(id) {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/recalculateRisks/${id}`;
    return this.$http.get(url).toPromise();
  }

  getAvailableResources(worklistId) {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/getAvailableResources/${worklistId}`;
    return this.$http.get(url);
  }

  onItemCreated(func) {
    this.itemCreated = func;
  }

  onItemUpdated(func) {
    this.itemUpdated = func;
  }

  onItemUnlockStatusChanged(func) {
    this.itemUnlockStatusChanged = func;
  }

  onItemRemovedStatusChanged(func) {
    this.itemRemovedStatusChanged = func;
  }

  getBarcode(accession) {
    const url = `${this.authService.currentMagViewUrl()}/api/worklist/getBarcode?accession=${accession}`;
    return this.$http.get(url).toPromise();
  }

  isChanged(model: any): boolean {
    for (const property in model.leftHistoryDataCopy) {
      if (model.leftHistoryDataCopy.hasOwnProperty(property)) {
        // 312
        // if (model.leftHistoryDataCopy[property] === '/  /') {
        //   model.leftHistoryDataCopy[property] = '//';
        // }
        // 312 ---

        if (model.leftHistoryData[property] === undefined || model.leftHistoryData[property] === null) {
          model.leftHistoryData[property] = '';
        }
        let propertyConfig = null;
        model.historyConfig.leftHistoryConfig.forEach(column => column.forEach(config => {
          if (config.id == property) {
            propertyConfig = config;
          }
        }));
        if (propertyConfig && (propertyConfig.type === 'AGE' || propertyConfig.type === 'NUM')
          && (model.leftHistoryDataCopy[property] === 0 || model.leftHistoryDataCopy[property] === '')) {
          model.leftHistoryDataCopy[property] = null;
        }
        if (propertyConfig && (propertyConfig.type === 'AGE' || propertyConfig.type === 'NUM')
          && (model.leftHistoryData[property] === 0 || model.leftHistoryData[property] === '')) {
          model.leftHistoryData[property] = null;
        }
        if (propertyConfig && propertyConfig.type === 'POP' && model.leftHistoryDataCopy[property] === '') {
          model.leftHistoryDataCopy[property] = null;
        }
        if (propertyConfig && propertyConfig.type === 'POP' && model.leftHistoryData[property] === '') {
          model.leftHistoryData[property] = null;
        }
      }
    }

    for (const property in model.examUfieldsDataCopy) {
      if (model.examUfieldsDataCopy.hasOwnProperty(property)) {
        if (model.examUfieldsDataCopy[property] === '/  /') {
          model.examUfieldsDataCopy[property] = '//';
        }
        if (model.examUfieldsData[property] === undefined || model.examUfieldsData[property] === null) {
          model.examUfieldsData[property] = '';
        }
      }
    }

    return !!model.patient && !!model.patient.study
      && (!_.isEqual(model.patient.study, model.patientCopy.study)
        || !_.isEqual(model.problems, model.problemsCopy)
        || !_.isEqual(model.leftHistoryDataCopy, model.leftHistoryData)
        || !_.isEqual(model.rightHistoryDataCopy, model.rightHistoryData)
        || !_.isEqual(model.examUfieldsDataCopy, model.examUfieldsData)
        || !_.isEqual(model.implantDataCopy, model.implantData)
        || this.isDiagramChanged(model)
      );
  }

  isDiagramChanged(model: any): boolean {
    let includeIndexProperty;
    if (model.problemsList && model.problemsListCopy && model.problemsList.length && model.problemsListCopy.length) {
      includeIndexProperty = model.problemsList[0].index && model.problemsListCopy[0].index;
    }
    const problemsListMapped = this.mapProblemsForComparison(model.problemsList, includeIndexProperty);
    const problemsListCopyMapped = this.mapProblemsForComparison(model.problemsListCopy, includeIndexProperty);
    return !_.isEqual(problemsListMapped, problemsListCopyMapped);
  }

  private mapProblemsForComparison(problemsList: any, includeIndexProperty: any) {
    return problemsList.map((el) => {
      const mappedObject: any = {
        Comments: el.comments,
        Settings: {
          height: el.settings.height,
          scale: el.settings.scale,
          path: el.settings.path,
          width: el.settings.width,
          x: el.settings.x,
          y: el.settings.y,
        },
        Type: el.type,
      };
      if (includeIndexProperty) {
        mappedObject.index = el.index;
      }
      return mappedObject;
    });
  }

  moveCorrectTimeStamps(model): void {
    if (model.examBeginTime) {
      model.examBeginTime = this.getLocalTimeString(model.examBeginTime);
    }
    if (model.examEndTime) {
      model.examEndTime = this.getLocalTimeString(model.examEndTime);
    }
  }

  private getLocalTimeString(utcTimeString: string) {
    const date = new Date();
    date.setUTCHours(Number(utcTimeString.split(':')[0]), Number(utcTimeString.split(':')[1]), Number(utcTimeString.split(':')[2]));
    const hours = date.getHours();
    let minutes = date.getMinutes().toString();
    if (minutes.length < 2) {
      minutes = `0${minutes}`;
    }
    let seconds = date.getSeconds().toString();
    if (seconds.length < 2) {
      seconds = `0${seconds}`;
    }
    return `${hours}:${minutes}:${seconds}`;
  }

  shouldDisableControls(model) {
    if (!model || !model.status || !this.isOnline) {
      return true;
    }
    return false;
  }

  isExamStarted(patient) {
    if (!patient) {
      return false;
    }

    return patient.elapsedTime || _.includes([
      WorklistStatuses.ExamInProgress,
      WorklistStatuses.ExamPaused,
    ], patient.status);
  }

  async checkIfActionCanBePerformed(worklistId, hideAlert?: boolean) {
    const studyLocked: any = await this.checkStudyLock(worklistId);
    if (studyLocked.isLock) {
      if (!hideAlert) {
        this.modalService.showAlert(`This exam is now taken by ${studyLocked.currentUser.userName}. You cannot perform this action`);
      }
      return false;
    }
    return true;
  }

  initPersonalBRCAValues(worklistModel: any) {
    worklistModel.personalBRCA1Value = worklistModel.leftHistoryData.PERSONAL_BRCA1;
    worklistModel.personalBRCA2Value = worklistModel.leftHistoryData.PERSONAL_BRCA2;
  }

  revertAllChanges(worklistModel: any) {
    worklistModel.patient = _.cloneDeep(worklistModel.patientCopy);
    worklistModel.problems = _.cloneDeep(worklistModel.problemsCopy);
    worklistModel.selectedProblems = worklistModel.getSelectedProblems(worklistModel.problems);
    worklistModel.problemsList = _.cloneDeep(worklistModel.problemsListCopy);
    worklistModel.examUfieldsData = _.cloneDeep(worklistModel.examUfieldsDataCopy);
    worklistModel.leftHistoryData = _.cloneDeep(worklistModel.leftHistoryDataCopy);
    this.initPersonalBRCAValues(worklistModel);
    worklistModel.rightHistoryData = _.cloneDeep(worklistModel.rightHistoryDataCopy);
    worklistModel.implantData = _.cloneDeep(worklistModel.implantDataCopy);
    worklistModel.implantString = worklistModel.getImplantString();
  }

  getEmptyRequiredFiledsHintString(validationResult: any) {
    let emptyRequiredFieldsHintString = '';
    if (validationResult.exam.length) {
      emptyRequiredFieldsHintString += '<br/><br/>Exam: <br/>&emsp;';
      emptyRequiredFieldsHintString += validationResult.exam.join(',<br/>&emsp;');
    }
    if (validationResult.demographic.length) {
      emptyRequiredFieldsHintString += '<br/><br/>Demographic: <br/>&emsp;';
      emptyRequiredFieldsHintString += validationResult.demographic.join(',<br/>&emsp;');
    }
    return emptyRequiredFieldsHintString;
  }

  stringifyProblemsSettings(worklistDetail: any): void {
    let isOldMode;
    if (worklistDetail.diagramEditor != null) {
      isOldMode = worklistDetail.diagramEditor.oldMode === true || typeof (worklistDetail.diagramEditor.oldMode) === 'undefined';
    } else {
      isOldMode = !this.techpadConfig.settings.newDiagramFeatureEnabled;
    }
    const problems = _.cloneDeep(worklistDetail.problemsList);
    problems.forEach((item) => {
      item.settings.newMode = !isOldMode;
      item.settings = JSON.stringify(item.settings);
    });
    worklistDetail.patient.problems = problems;
  }

  checkPersonalBRCAHistory(worklistDetail: any): boolean {
    let isValid = true;
    if ((worklistDetail.personalBRCA1Value === '') || (worklistDetail.personalBRCA2Value === '')) {
      isValid = false;
      worklistDetail.notValidPersonalBRCAHistory = true;
    } else {
      worklistDetail.notValidPersonalBRCAHistory = false;
    }
    return isValid;
  }

  getDuration(worklistDetail, showMilliseconds?: boolean) {
    let result;

    if (showMilliseconds) {
      if (!worklistDetail.patientCopy.currentTime) {
        let currentTime = (new Date()).getTime() - worklistDetail.patientCopy.elapsedTime / 10000;

        const startDate = new Date(`${worklistDetail.patientCopy.startTime}Z`);
        // added '+' here to convert types
        const offset = (+new Date() - +startDate + this.serverTimeService.getDiff());
        if (offset > 0) {
          currentTime -= offset;
        }
        worklistDetail.patientCopy.currentTime = currentTime;
      }

      result = worklistDetail.patientCopy.currentTime;
    } else {
      let seconds = worklistDetail.patientCopy.elapsedTime / 10000 / 1000;
      const hours = Math.floor(seconds / 3600);
      seconds -= hours * 3600;
      const minutes = Math.floor(seconds / 60);
      seconds = Math.floor(seconds - minutes * 60);

      result = `${(hours < 10 ? '0' : '') + hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

    return result;
  }

  async getDiagramBase64String() {
    // TODO: 311 - brought from old techpad, need to fix later
    // return this.vm.diagramEditor.getDiagramImage();
    // 311 ----------
  }

  getLabel(value, collection, key?, property?): string {
    let selectedItem = collection.find(x => x && x[key || 'key'] !== undefined && x[key || 'key'] === value);

    if (!selectedItem) {
      selectedItem = {};
    }

    return selectedItem[property || 'value'];
  }
}
