import {
  Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '../../constants/ui-config.constants';
import { IonSpinnerNameEnum } from '../../enums/ion-spinner.enum';
import { LoadMoreEventCallbackModel } from '../../models/load-more-event-callback.model';
import { LoadMoreEventModel } from '../../models/load-more-event.model';

@Component({
  selector: 'app-ion-input-autocomplete-modal',
  templateUrl: './ion-input-autocomplete-modal.component.html',
  styleUrls: ['./ion-input-autocomplete-modal.component.scss'],
})
export class IonInputAutocompleteModalComponent implements OnInit {
  @Input() cancelLabel: string;

  @Input() itemsBehaviorSubject: Subject<any[]>;

  @Input() loadingIcon: IonSpinnerNameEnum | undefined;

  @Input() placeholder: string;

  @Input() selectItemsLabel: string;

  @Input() selectedItemLabel: string;

  @Input() itemValueKey: string;

  @Input() itemViewValueKey: string;

  @Input() showLoadMore = true;

  @Input() pageIndex = 0;

  @Input() searchDebounceTime;

  @Output() loadMore: EventEmitter<any>;

  @Output() loadItems: EventEmitter<any>;

  searchQuery: string;

  selectedItem: any;

  items: any[] = [];

  showLoadingIcon = false;

  private searchQueries = new Subject<string>();

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
    this.itemsBehaviorSubject.subscribe((newItems: any) => {
      this.items = newItems;
    });

    this.searchQueries.pipe(
      debounceTime(this.searchDebounceTime ? this.searchDebounceTime : DEFAULT_SEARCH_DEBOUNCE_TIME),
      distinctUntilChanged(),
      map((query: string) => this.loadItemsForQuery(query)),
    ).subscribe();
  }

  async onCancelClicked($event: MouseEvent) {
    await this.modalController.dismiss(this.selectedItem);
  }

  async onDeselectButtonClick($event: MouseEvent) {
    await this.modalController.dismiss(null);
  }

  loadItemsForQuery(searchQuery: string) {
    this.showLoadingIcon = true;
    const loadMoreEventsModel: LoadMoreEventModel = {
      searchQuery: searchQuery || '',
      pageIndex: 0,
      callback: (loadMoreEventCallback: LoadMoreEventCallbackModel) => {
        if (loadMoreEventCallback.isSuccess) {
          this.searchQuery = searchQuery;
        }
        if (!loadMoreEventCallback.showLoadButton) {
          this.showLoadMore = false;
        }
        this.showLoadingIcon = false;
      },
    };
    this.loadItems.emit(loadMoreEventsModel);
  }

  async onItemClick(item: any): Promise<void> {
    await this.modalController.dismiss(item);
  }

  search(searchQuery: string) {
    this.searchQueries.next(searchQuery);
  }

  onLoadMoreClick($event: MouseEvent) {
    const nextPageIndex = this.pageIndex + 1;
    this.showLoadingIcon = true;
    const loadMoreEventModel = {
      searchQuery: this.searchQuery || '',
      pageIndex: nextPageIndex,
      callback: (loadMoreEventCallback: LoadMoreEventCallbackModel) => {
        if (loadMoreEventCallback.isSuccess) {
          this.pageIndex = nextPageIndex;
        }
        if (!loadMoreEventCallback.showLoadButton) {
          this.showLoadMore = false;
        }
        this.showLoadingIcon = false;
      },
    } as LoadMoreEventModel;
    this.loadMore.emit(loadMoreEventModel);
  }
}
