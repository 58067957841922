import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HeaderService } from '../../../../../app/modules/core/services/header.service';
import { AuthProvider } from '../../../../../app/modules/core/contracts/auth.provider';
import { AuthService } from '../../services/auth.service';
import { ModalService } from '../../../../../app/modules/core/services/modal.service';

@Component({
  templateUrl: 'next-login.component.html',
  styleUrls: ['next-login.component.scss']
})
export class NextLoginComponent implements OnInit, OnDestroy {
  username = '';
  password = '';
  message = '';

  loading = false;

  constructor(private authService: AuthService,
    private headerService: HeaderService,
    private activatedRoute: ActivatedRoute,
    private modalDialogService: ModalService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.authService.logout();
  }

  ionViewWillEnter() {
    this.headerService.updateShouldShowHeader(false);
  }

  ngOnDestroy(): void {
  }

  async onLogin() {
    await this.authService.login(this.username, this.password);
    await this.router.navigate(['/', 'worklist']);
  }
}
