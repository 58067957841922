import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from '../../../../app/modules/core/services/local-storage.service';
import { AuthResponseModel } from '../models/auth-response.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HeaderService } from '../../../../app/modules/core/services/header.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthProvider } from '../../../../app/modules/core/contracts/auth.provider';
import { UserModel } from '../models/user.model';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService implements AuthProvider {

  l35nextTokenKey = 'next-authtoken';

  get loggedIn(): boolean {
    return !!this.getAuthToken();
  }

  loggedIn$ = new BehaviorSubject<any>(false);

  private subscription: Subscription = new Subscription();

  constructor(private httpClient: HttpClient,
    private router: Router,
    private localStorage: LocalStorageService,
    private headerService: HeaderService) {
    this.subscription.add(this.headerService.logoutEvent.subscribe(() => {
      this.logout();
      this.router.navigate(['/', 'login']);
    }));

    this.loggedIn$.next(this.loggedIn);
  }

  logout() {
    this.localStorage.delete(this.l35nextTokenKey);
    this.loggedIn$.next(false);
  }

  getUserName(): string {
    const user = this.getUserData();
    return user.UserName;
  }

  getDisplayName(): string {
    const user = this.getUserData();
    let displayName = '';

    if (user) {
      displayName = user.UserFirstName + ' ' + user.UserLastName
    }

    return displayName;
  }

  getUserData(): UserModel {
    const token = this.getAuthToken();
    let user = null;

    if (token) {
      user = AuthService.decodeJwt(token).payload as UserModel;
    }

    return user;
  }

  static decodeJwt(token) {
    if (!token) {
      return null;
    }

    const stringSplit = token.split('.');

    const tokenObject: any = {};
    tokenObject.raw = tokenObject;
    tokenObject.header = JSON.parse(window.atob(stringSplit [0]));
    tokenObject.payload = JSON.parse(window.atob(stringSplit [1]));
    return (tokenObject);
  }

  getAuthToken(): string {
    return this.localStorage.get(this.l35nextTokenKey);
  }

  setAuthToken(token) {
    this.localStorage.set(this.l35nextTokenKey, token);
  }

  refreshToken() {
    const token = this.getAuthToken();
    if (!token) {
      throw new Error('No token found');
    }
    const data = AuthService.decodeJwt(token);
    const refreshToken = data.payload.RefreshToken;

    const url = environment.serverUrl +'/api/Membership.Api/auth/refreshToken';
    return this.httpClient.post(url, {
      refreshToken
    }).pipe(map((response: any) => {
      this.setAuthToken(response.token);
      return response;
    }));
  }

  async login(username, password): Promise<AuthResponseModel> {
    const url = environment.serverUrl + '/api/Membership.Api/auth';

    const response = await this.httpClient.post<AuthResponseModel>(url, {
      userName: username,
      password: password
    }).toPromise();

    if (response.token) {
      this.localStorage.set(this.l35nextTokenKey, response.token);
      this.loggedIn$.next(true);
    }

    return response;
  }
}
