import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'generic-worklist-details',
  templateUrl: './generic-worklist-details.component.html',
  styleUrls: ['./generic-worklist-details.component.scss'],
})
export class GenericWorklistDetailsComponent implements OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }
  @Input()
  viewModel: any;
}
