import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { environment } from '../environments/environment';
import { RealtimeConnectionService } from '../app/modules/core/services/realtime.service';
import { AuthService } from './modules/membership/services/auth.service';
import { WorklistRealtimeService } from '../app/modules/core/services/worklist-realtime.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private realtimeConnection: RealtimeConnectionService,
    private worklistRealtimeService: WorklistRealtimeService,
    private authService: AuthService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.authService.loggedIn$.subscribe(async (isLoggedIn) => {
      if (isLoggedIn) {
        if (this.realtimeConnection.initialized && !this.realtimeConnection.connected) {
          await this.realtimeConnection.connect();
        } else {
          const url = environment.serverUrl + `/realtimeHub`;
          await this.realtimeConnection.init(url, () => this.authService.getAuthToken());
        }
      } else if (this.realtimeConnection.connected) {
          await this.realtimeConnection.disconnect();
      }
    });
  }
}
