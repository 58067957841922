import { Component, OnInit } from '@angular/core';
import { applicationConfiguration } from 'src/app/config';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent implements OnInit {
  configuration = applicationConfiguration;

  constructor() { }

  ngOnInit() {}

}
