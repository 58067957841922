import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { CoreModule } from '../app/modules/core/core.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/membership/services/auth.service';
import { AuthGuard } from './modules/membership/services/guards/auth.guard';
import { NextMembershipModule } from './modules/membership/next-membership.module';
import { AppNextRoutingModule } from './app-next-routing.module';
import { NextTokenInterceptor } from './modules/membership/interceptor/next-token.interceptor';
import { AuthProvider } from '../app/modules/core/contracts/auth.provider';
import { WorklistStatusProvider } from '../app/modules/core/contracts/worklist-status.provider';
import { NextWorklistStatusProviderService } from './modules/worklist-order/services/next-worklist-status-provider.service';
import { WorklistTagsProvider } from '../app/modules/core/contracts/worklist-tags.provider';
import { NextWorklistTagsProviderService } from './modules/worklist-order/services/next-worklist-tags-provider.service';
import { WorklistResourcesProvider } from '../app/modules/core/contracts/worklist-resources.provider';
import { NextWorklistResourcesProviderService } from './modules/worklist-order/services/next-worklist-resources-provider.service';
import { NextErrorInterceptor } from './modules/membership/interceptor/next-error.interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppNextRoutingModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    NextMembershipModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    {
      provide: AuthProvider,
      useClass: AuthService
    },
    AuthGuard,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NextTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NextErrorInterceptor,
      multi: true
    },
    {
      provide: WorklistStatusProvider,
      useClass: NextWorklistStatusProviderService
    },
    {
      provide: WorklistTagsProvider,
      useClass: NextWorklistTagsProviderService
    },
    {
      provide: WorklistResourcesProvider,
      useClass: NextWorklistResourcesProviderService
    },
    {
      provide: AuthProvider,
      useClass: AuthService
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppNextModule {
}
