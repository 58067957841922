import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChatModuleConfiguration } from '../../../сhat-config';
import { AuthService } from './auth.service';
import { DataRetrieverService } from '../../core/services/data-retriever.service';
import { pubsubService } from '../../core/services/pubsub.service';
import { TechpadOptionsService } from './techpad-options.service';

@Injectable()
export class TechpadConfigService {

  _techpadSettingsSubject = new BehaviorSubject<any>(null);
  techpadSettings = this._techpadSettingsSubject.asObservable();

  get settings() {
    return this._techpadSettingsSubject.value;
  }

  set settings(value) {
    this._techpadSettingsSubject.next(value);
  }

  private _promise: Promise<any>;

  // TODO: Init version number
  appVersionNumber: string;


  constructor(private $http: HttpClient,
    private techpadOptions: TechpadOptionsService,
    private dataRetrieverService: DataRetrieverService,
    private authService: AuthService) {
  }

  init() {
    if (!this._promise) {
      this._promise = new Promise<any>(resolve => {
        this.authService.ensureMagviewPortal().then(async () => {
          await this.loadSettings(resolve, null);
          this.techpadOptions.init();
        });
      });

    }
    return this._promise;
  }


  reload() {
    this.settings = null;
    this._promise = null;

    return this.init();
  }

  reloadByMagViewUrl(url) {

    this.settings = null;

    this._promise = new Promise<any>(resolve => {
      this.loadSettings(resolve, url);
    });

    return this._promise;
  }

  async loadSettings(resolve, magviewUrl) {
    try {
      const resp: any = await this.dataRetrieverService.cachedProxy('techpad-settings', 0, () => {
        return this.loadTechpadSettings(magviewUrl);
      });

      this.settings = resp;

      if (this.settings && this.settings.isShowChatMenuItem) {
        ChatModuleConfiguration.apiEndpoint = this.settings.chatServiceUrl;
        for (const key in ChatModuleConfiguration.storageKey) {
          if (ChatModuleConfiguration.storageKey.hasOwnProperty(key) &&
            ChatModuleConfiguration.storageKey[key].indexOf(this.appVersionNumber) === -1
          ) {
            ChatModuleConfiguration.storageKey[key] = `${this.appVersionNumber}_${ChatModuleConfiguration.storageKey[key]}`;
          }
        }

        if (this.authService.loggedIn) {
          pubsubService.dispatch('CHAT_MODULE_MAKE_INITIAL_LOAD');
        }
      }

      resolve(resp);
    } catch (error) {
      this.settings = {};
      resolve();
    }
  }

  private loadTechpadSettings(magviewUrl) {
    if (!magviewUrl) {
      magviewUrl = this.authService.currentMagViewUrl();
    }
    const url = `${magviewUrl}/api/worklist/TechpadSettings`;
    let options = { headers: {} };
    if (!this.authService.loggedIn) {
      options.headers = this.authService.getTechpadDiagramHeaders();
    }
    return this.$http.get(url, options).toPromise();
  }


  onSettingChanged(newSettings) {
    this.settings = newSettings;
  }

  registerOnSettingChange(fun) {
    // this.signalrService.worklist.on('settingChanged', fun);
    //
    // return {
    //     unsubscribe: () => {
    //         this.signalrService.worklist.off('settingChanged', fun);
    //     }
    // };
  }

}
