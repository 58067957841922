import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { WorklistTagsProviderService } from 'src/app/modules/techpad-core/services/worklist-tags-provider.service';
import { WorklistTagsProvider } from '../../../../modules/core/contracts/worklist-tags.provider';

@Component({
  selector: 'app-worklist-change-tags',
  templateUrl: './worklist-change-tags.component.html',
  styleUrls: ['./worklist-change-tags.component.scss'],
})
export class WorklistChangeTagsComponent implements OnInit {

  @Input()
  id: number;

  @Input()
  item: any;

  availableTags: any[] = [];
  tagsSelectionMap: any = {};

  constructor(private popoverController: PopoverController,
    private worklistTagsProvider: WorklistTagsProvider) {
  }

  async ngOnInit() {
    this.availableTags = await this.worklistTagsProvider.getAvailableTags();
    const selectedTags = this.item.tags.map(x => x.value) || [];
    this.availableTags.forEach(tag => {
      this.tagsSelectionMap[tag.value] = selectedTags.some(t => t === tag.value);
    });
  }

  toggleTag(tag: any) {
    this.popoverController.dismiss({ item: this.item, tag: tag });
  }
}
